export default {
  "page-before-starting-test": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vizsgálat megkezdése előtt"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegyen egy szabványos <span class=\"bold\">kártyát</span>, ez elengedhetetlen lesz az Ön számára a teszthez."])},
    "take-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])}
  },
  "page-distance": {
    "select-distance": {
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválasztott"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdet 40 cm"])},
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagy távolság egy segítővel<br>kb. 2 méteres távolságban"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 2 méter"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvasási távolság magától<br>körülbelül 40 cm"])}
    },
    "info": {
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Útmutató a látásvizsgálathoz</h3><p>Egyedül is tesztelheti az olvasási látását.<br><span class='underline'>Hogyan tesztelheti az olvasási látását:</span></p><div class='first'><div class='text'><p>1. Helyezze a képernyőt 40 cm-re a szemétől. Mérje meg pontosan a távolságot.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in eleven rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Ha készen áll, nyomja meg a \"Folytatás\" gombot.</p>"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Útmutató a távolbalátás vizsgálatához</h3><p>A távolbalátás vizsgálatához egy segítőre lesz szüksége.<br><span class='underline'>Hogyan kell tesztelni a távolbalátást.</span></p><div class='first'><div class='text'><p>1. Helyezze a képernyőt 2 méterre a szemétől. Mérje meg pontosan a távolságot. Segítője álljon a képernyő mellé.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in twelve rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Your helper should enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Nyomja meg a \"Teszt\" gombot, amikor mindketten készen állnak.</p>"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szemvizsgálat"])},
    "set": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Távolság kiválasztásaTesztelheti</h2>látását két távolságból is."])},
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])}
    }
  },
  "page-how-it-works": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indítsa el a  oldalt."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan működik"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a gyűrűnyílást a <span class='bold'>billentyűzet irányjelző nyilával</span>, vagy <span class='bold'>kattintson a gombokra</span>, vagy <span class='bold'>húzza át a</span> gombot."])}
  },
  "page-results": {
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már elvégezte a távoli látásvizsgálatot\n"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha egy optometrista nem tud segíteni Önnek abban, hogy tisztán lásson, fontos, hogy kiderítse, miért.<br />Mindig forduljon szemészhez, hogy megtudja, miért."])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Ön most befejezte az olvasási látásvizsgálatot.</h3><p>Ön azt válaszolta, hogy 40 cm-es távolságból tisztán látja a ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " szimbólumokat.<br>Mások ugyanilyen távolságból a 40 szimbólumból 36-ot látnak tisztán<sup>.*</sup><br> *<em class='small'> Forrás; Lisbeth Jelveh Sandfeld, PhD, vezető tanácsadó. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463 <a href='https://doi.org/10.1371/journal.pone.0188463'>. https://doi.org/10.1371/journal.pone.0188463</a>.</em></p>"])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Ön most már elvégezte a távoli látásvizsgálatot.</h3><p>Ön azt válaszolta, hogy 2 méteres távolságból tisztán látja a ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " szimbólumokat.<br>Mások ugyanilyen távolságból 40 szimbólumot látnak tisztán<sup>.*</sup><br> *<em class='small'> Forrás; Lisbeth Jelveh Sandfeld, PhD, vezető tanácsadó. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463 <a href='https://doi.org/10.1371/journal.pone.0188463'>. https://doi.org/10.1371/journal.pone.0188463</a>.</em></p>"])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Köszönjük, hogy elvégezte a DIY Digital Visual Acuity Testet az eye-sight.org oldalon.<br>A látásával kapcsolatos bármilyen orvosi kérdéssel vagy aggodalommal mindig forduljon optikushoz vagy szemészhez.<br>"])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új teszt kiválasztása"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Eredmények 2 méteren:</b> bal szem ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már elvégezte az olvasási látásvizsgálatot\n"])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Eredmények 40 cm-nél:</b> bal szem ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a látomásod alul van"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyéb"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Helyesen azonosította a ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols at a ", _interpolate(_named("distanceKey")), " distance.<br>Most people are able to identify ", _interpolate(_named("normalVisionSteps")), " out of the ", _interpolate(_named("steps")), " szimbólumokat."])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Valószínűleg előnyös lenne, ha felkeresne egy optikust. Ha az optikus nem tud segíteni Önnek abban, hogy tisztán lásson, fontos, hogy megtudja, miért. Ebben egy szemész szakorvos (szemész) tud Önnek segíteni.<br><br>A látásával kapcsolatos bármilyen orvosi kérdéssel vagy aggodalommal mindig forduljon optikushoz vagy szemész szakorvoshoz."])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Köszönjük, hogy részt vett a DIY Digital Visual Acuity Test-en az eye-sight.org oldalon. Ha nem biztos abban, hogy jó a látása, először is forduljon optikushoz. Ha az optikus nem tud segíteni Önnek abban, hogy tisztán lásson, fontos, hogy megtudja, miért. Ebben egy szemész (szemész) tud Önnek segíteni.<br><p>Ez a látásvizsgálat:</p><ul><li>Nem orvosi eszköz.</li>Nem<li>alkalmas semmilyen orvosi célra.</li><li>Egészséges egyének számára készült.</li><li>Nem helyettesíti a professzionális látásvizsgálatot.</li><li>Nem gyűjt Önről vagy bárki másról semmilyen személyes, azonosítható adatot. </li></ol><p>A látásával kapcsolatos bármilyen orvosi kérdéssel vagy aggodalommal mindig forduljon optikushoz vagy szemészhez.</p>"])}
  },
  "global": {
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takard el a bal szemed"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a gyűrűnyílást a nyílbillentyűvel, a gombokra kattintva vagy az ujjlenyomással."])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem kompatibilis böngésző"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 méter"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredmények"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Képernyő beállítása"])},
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Távolság kiválasztása"])},
    "screen-warning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A képernyő túl keskeny a digitális látásélesség teszt elvégzéséhez. "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha okostelefont használ, és függőlegesen tartja, próbálja meg vízszintesen megdönteni a telefont."])}
    },
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alap üzemmódba lépés"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essentials"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvassa el az utasítást"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön aktuális böngészője nem támogatott. Kérjük, használja a következő böngészők egyikét:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome for iOS vagy Android"])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilon nem használható a speciális verzió"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takard el a jobb szemed"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Látásvizsgálat"])])}
  },
  "page-scale": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szemvizsgálat"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kisebb -1"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kisebb -5"])},
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Nagyobb</p>"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyobb +1"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagyobb +5"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kész"])},
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Kisebb</p>"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your mobile phone on a table.</li><li>Place a credit card sized card on the screen.</li><li>Place the narrow side of the card between the crop marks.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>A képernyő méretének beállítása</h2><ol><li>Helyezzen egy szabványos kártyát a képernyőre a kivágási jeleken belül.</li><li>Állítsa be a képernyő méretét a gombok segítségével.</li><li>Állítsa be a kivágási jeleket úgy, hogy azok a hitelkártya szélességével egyezzenek meg.</li></ol>"])}
  },
  "page-keep-screen-horizontal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartsa a képernyőt vízszintesen"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A teszt során a képernyőt vízszintesen kell tartania."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])}
  },
  "page-checklist": {
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljesen felhangosítottam a fényerőt"])},
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megmértem, hogy a szemem 40 cm / 2 méterre van a képernyőtől."])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testreszabtam a képernyőt egy hitelkártya alapján."])},
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szemüveget viselek (ha viselsz szemüveget)"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oké, készen állok."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó utasítás..."])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bent vagyok"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A képernyőt vízszintesen tartom"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bal szememet tartom"])}
  },
  "page-intro": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szemvizsgálat"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indítsa el a  oldalt."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Osztályozás:</span><br/>\nMDR 2017/745 orvostechnikai eszközrendelet VIII. mellékletének 11. szabálya szerint I. osztály: I. osztály.<br/><br/>\n\nKijelentjük, hogy a CE-jelöléssel ellátott DIY digitális látásélesség-vizsgáló rendszer megfelel az MDR 2017/745 orvostechnikai eszközrendelet I. mellékletében foglalt általános biztonsági és teljesítménykövetelményeknek. Az alkalmazandó harmonizált szabványok alapján igazoltuk az eszköz biztonságosságát és hatékonyságát, ha a rendeltetésszerű használatnak és a mellékelt használati utasításnak megfelelően használják.<br/><br/>\n\nA következő szabványokat használták:<br/><br/>\n\n<span class=\"body-bold\">Gyártás</span>: EN ISO 13485:2016<br/>\n<span class=\"body-bold\">Kockázatkezelés</span>: EN ISO 14971:2019<br/>\n<span class=\"body-bold\">Szoftver életciklus</span>: EN 62304:2006/A1:2015<br/><br/>\n\nA szoftver minden egyes verzióját az előírásoknak való megfelelést igazoló nyilvántartásoknak megfelelően adják ki."])},
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Látogasson el az eye-sight.org weboldalra, ha többet szeretne megtudni.</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen Værn om Synet<br/>Ny Kongensgade 20<br/>1557 Koppenhága V"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens<br />ingyenes, online do-it-yourself látásvizsgálat"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontak", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])}
  },
  "page-keep-them": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tartsd meg őket"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha szemüveget vagy kontaktlencsét visel, tartsa meg."])}
  },
  "page-in-full-brightness": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes fényerőben"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolja fel teljesen a képernyő háttérvilágítását."])}
  },
  "page-test": {
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takarja el a jobb szemét, és tartsa a képernyőt ", _interpolate(_named("distance")), " távol a szemétől."])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobbra"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most a jobb szemedet tesztelted."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Most tesztelheti a bal szemét.<br>Takarja el a <strong>jobb</strong> szemét.<br>Tartsa a képernyőt ", _interpolate(_named("distance")), " távol a szemétől<br>Nyomja meg a \"Következő kör\" gombot.</p>"])}
    },
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Tesztelés</strong>"])},
    "notice-too-many-errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Két hiba"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ön most a bal szemét tesztelte, és befejezte a tesztet. <br>Nyomja meg az \"Eredmények megjelenítése\" gombot.</p>"])},
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Most tesztelte a jobb szemét.<br>Most tesztelheti a bal szemét.<br>Takarja el a <strong>jobb</strong> szemét.<br>Tartsa a képernyőt ", _interpolate(_named("distance")), " távol a szemétől<br>Nyomja meg a \"Következő kör\" gombot.</p>"])}
    },
    "made-two-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Két hibát vétettél."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indítsa el a  oldalt."])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdje a bal szemének eltakarásával"])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredmények megjelenítése"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépés"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hagyja el a  címet."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szemvizsgálat"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő forduló"])},
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Változtasd meg a szemed"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lefelé"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balra"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó visszavonása"])}
  },
  "page-measure-40-cm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mérje meg a távolságot<br>vonalzóval"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 darab A4-es papír"])},
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyezze a képernyőt 40 cm-re a szemétől"])},
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyezze a képernyőt 2 méterre a szemétől"])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi az a 40 cm?"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])}
  },
  "page-2-metres": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segítőre van szükséged a hosszú távú teszthez"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legyen 2 méterre a számítógéptől, a segítője álljon a képernyő mellett, és jelezze Önnek a válaszokat."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])}
  },
  "page-proper-dimensions": {
    "proper-device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A készülék tényleges képernyőmérete nem támogatott<br />Az ablak méretének legalább 24 cm x 15 cm-nek kell lennie<br />Kérjük, váltson kompatibilis készülékre."])}
  }
}
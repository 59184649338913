<template>
  <div class="page">
    <div class="page-intro">
      <div class="content">
        <h2 class="intro-title"
            v-html="isAdvancedMode ?  $t('page-intro.top-title-advanced') : $t('page-intro.top-title')"></h2>
        <p class="text-center" v-html="isAdvancedMode ? $t('page-intro.intro-advanced') : $t('page-intro.intro')"></p>

        <div class="actions">
          <button class="btn primary-bg body-bold intro-button" v-on:click="goToNextPage">
            {{ $t('page-intro.calibrate-screen-button') }}
          </button>
        </div>
        <h4 class="h-5 margin-top-s text-center" v-if="isAdvancedMode">{{
            $t('global.investigational-only-title')
          }}</h4>
        <div class="investigational-only " v-if="isAdvancedMode">
          <div class="left">
            <img class="intro-img investigational-only-img" :src="investigationalOnly" alt="infos"/>
          </div>
          <div class="right">
            <p class="body margin-top-s margin-bottom-m"
               v-html="$t('page-intro.investigational-only-advanced-text')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgInfos from '@/assets/pageIntro-infos-transparent-bg.png';
import imgFactoryIso_7000_3082 from '@/assets/iso_grs_7000_3082.svg';
import imgLogo from '@/assets/Logo_da.png';
import imgCELogo from '@/assets/ce_logo.svg';
import imgMDLogo from '@/assets/MD.svg';
import imgREFLogo from '@/assets/REF.svg';
import imgUDILogo from '@/assets/UDI.svg';
import qrCodeAdvanced from '@/assets/QR Code - ADVANCED.Url.png';
import qrCodeBasic from '@/assets/QR Code - BASIC.Url.png';
import packageJson from '@/../package.json';
import investigationalOnly from '@/assets/investigational_only.png';

import {testModes} from "@/constants";

export default {
  data() {
    return {
      imgInfos,
      packageJson,
      imgFactoryIso_7000_3082,
      imgLogo,
      imgCELogo,
      imgMDLogo,
      imgREFLogo,
      imgUDILogo,
      qrCodeAdvanced,
      qrCodeBasic,
      investigationalOnly,
      fullscreenEnabled: false,
    };
  },
  methods: {
    async goToNextPage() {
      try {
        await this.toggleFullScreen();
      } catch (e) {
        console.error(e);
      }
      this.$changePageWithAnalyticsStep(this.$store, 'PageBeforeStartingTest');
    },
    async toggleFullScreen() {
      if (document.fullscreenEnabled && !document.fullscreenElement) {
        try {
          await document.documentElement.requestFullscreen();
          this.fullscreenEnabled = true;
          console.log('Fullscreen enabled');
        } catch (err) {
          this.fullscreenEnabled = false;
          throw new Error(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        }
      }
    },
  },
  computed: {
    isAdvancedMode() {
      return this.$store.state.testMode === testModes.ADVANCED;
    },
    versionMajorMinor() {
      return this.packageJson.version.split('.').slice(0, 2).join('.');
    },
  },
};
</script>

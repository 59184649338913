import {createStore} from 'vuex';
import moment from 'moment';
import {Logmar} from './utils/logmar';
import {generateRandomRingsOrientation, getIndexBasedOnDistance} from './utils/utils';
import {router} from './main.js';

import {
  creditCardDimensions,
  firstEye,
  logmarAdvancedConversion,
  logmarBasicConversion,
  maxErrorsPerTest,
  noticeTypes,
  roundStatus,
  secondEye,
  testAdvancedConfiguration,
  testBasicConfiguration,
  testModes,
} from './constants';
import i18n from './i18n';

// import {useI18n} from "vue-i18n";

// const {t} = useI18n()

const calculateSizesForLogmar = (logmar, testingDistance, creditCardRatio, ringCount) => {
  // size is in cm
  const size = new Logmar(logmar).diameter(testingDistance);

  // width of a single symbol
  const symbolSize = Math.ceil(size * creditCardRatio);

  // padding around each symbol
  const paddingAroundSymbol = Math.ceil(symbolSize / 2);

  // border of the box around all the symbols
  const borderWidthAroundAllSymbols = Math.max(1, Math.ceil(symbolSize * 0.2));

  // with of all the symbols (without border)
  const widthAllSymbolsAndBorder =
    ringCount * (symbolSize + 2 * paddingAroundSymbol) + 2 * borderWidthAroundAllSymbols;
  const heightAllSymbolsAndBorder =
    symbolSize + 2 * paddingAroundSymbol + 2 * borderWidthAroundAllSymbols;

  // Width/height of the progress bullet
  const progressBulletSize = Math.min(22, Math.ceil(symbolSize / 2));

  // Height between the symbol and the progress bullet
  const progressBulletSpacingFromSymbol =
    paddingAroundSymbol + borderWidthAroundAllSymbols + progressBulletSize + 5;

  // Margin around the box
  const marginAroundBox = {
    top: 24 * 2.5,
    right: 24 * 2.5,
    bottom: 24 * 2.5,
    left: 24 * 2.5,
  };

  return {
    symbolSize,
    paddingAroundSymbol,
    borderWidthAroundAllSymbols,
    widthAllSymbolsAndBorder,
    heightAllSymbolsAndBorder,
    progressBulletSize,
    progressBulletSpacingFromSymbol,
    marginAroundBox,
  };
};



export default createStore({
  state() {
    return {
      currentPage: 'PageIntro',
      entryID: null,
      mainFieldID: null,
      roundsPerEye: testBasicConfiguration['40cm'].logmar.length, // this should not really be like this
      creditCardWidth: 500,
      firstLaunch: true,
      creditCardHeight: 500 / creditCardDimensions.ratio,
      distance: -1,
      testData: {},
      checklist: [],
      language: 'en',
      timerStart: null,
      currentPathCompleted: '',
      duration_test: null,
      showModal: false,
      onModalButton1Click: () => {},
      onModalButton2Click: () => {},
      componentToShowInsideModal: '',
      argumentsToPassToAboveComponent: '',
      modalButtonActionText1: null,
      modalButtonActionText2: null,
      testMode: testModes.BASIC,
      showAboutButtonOnTopBar: true,
    };
  },
  getters: {
    getTestConfiguration(state) {
        return (state.testMode === testModes.BASIC ? testBasicConfiguration : testAdvancedConfiguration);
    },
    getLogmarConversion(state) {
        return state.testMode === testModes.BASIC ? logmarBasicConversion : logmarAdvancedConversion;
    },
    getDistance() {
        return this.distance;
    }
  },
  mutations: {
    startTimer(state) {
      state.timerStart = moment();
    },

    stopTimer(state) {
      state.duration_test = moment().diff(moment(state.timerStart), 'seconds');
    },

    setEntryID(state, id) {
        state.entryID = id;
    },

    setFirstLaunch(state, value) {
        state.firstLaunch = value;
    },

    setMainFieldID(state, id) {
        state.mainFieldID = id;
    },

    setCurrentPathCompleted(state, path) {
        state.currentPathCompleted = path;
    },

    setChecklist(state, checklist) {
      state.checklist = checklist;
    },
    setLanguage(state, language) {
        state.language = language;
    },
    goToAboutPage(state) {
      state.canPressKeyOrSwipe = false;
      this.commit('showModal',
          {
            componentToShowInsideModal: 'BeforeShowingAboutPage',
            argumentsToPassToAboveComponent: {'eyeToTest': firstEye},
            modalButtonActionText1: i18n.global.t('global.go-to-about-page'),
            onModalButton1Click: () => {
              router.push('/about')
              this.commit('changePage', 'PageAbout');
              this.commit('hideModal');
              this.canPressKeyOrSwipe = true;
            },
            modalButtonActionText2: i18n.global.t('global.cancel'),
            onModalButton2Click: () => {
              this.commit('hideModal');
              this.canPressKeyOrSwipe = true;
            }
          }
      );
    },
    changePage(state, pageName) {
      state.currentPage = pageName;
    },
    increaseCreditCardBoxWidth(state, amount) {
      state.creditCardWidth += amount;
      state.creditCardHeight = state.creditCardWidth / creditCardDimensions.ratio;
    },

    setDistance(state, distance) {
      state.distance = distance;
    },
    showModal(state, {componentToShowInsideModal, argumentsToPassToAboveComponent, modalButtonActionText1, onModalButton1Click, modalButtonActionText2 = null, onModalButton2Click = null}) {
      state.componentToShowInsideModal = componentToShowInsideModal;
      state.argumentsToPassToAboveComponent = argumentsToPassToAboveComponent;
      state.modalButtonActionText1 = modalButtonActionText1;
      state.modalButtonActionText2 = modalButtonActionText2;
      state.showModal = true;
      state.onModalButton1Click = onModalButton1Click;
      state.onModalButton2Click = onModalButton2Click;
    },
    hideModal(state) {
      state.componentToShowInsideModal = null;
      state.argumentsToPassToAboveComponent = null;
      state.modalButtonActionText1 = null;
      state.modalButtonActionText2 = null;
      state.showModal = false;
      state.onModalButton1Click = () => {};
      state.onModalButton2Click = () => {};
    },
    setTestMode(state, mode) {
      state.testMode = mode;
      console.log('setTestMode', mode, testModes.ADVANCED, testModes.BASIC);
      switch (mode) {
        case testModes.ADVANCED:
            state.logmarConversion = logmarAdvancedConversion;
            state.testConfiguration = testAdvancedConfiguration;
            console.log('advanced', state.logmarConversion, state.testConfiguration);
            break;
        case testModes.BASIC:
        default:
            state.logmarConversion = logmarBasicConversion;
            state.testConfiguration = testBasicConfiguration;
            break;
      }
    },
    setShowAboutButtonOnTopBar(state, show) {
        state.showAboutButtonOnTopBar = show;
    },
    initTest(state) {
      const idx = getIndexBasedOnDistance(state.distance);

      const testConfiguration = (state.testMode === testModes.BASIC ? testBasicConfiguration : testAdvancedConfiguration)[idx];

      state.roundsPerEye = testConfiguration.logmar.length;

      state.testData = {
        rounds: {
          left: [],
          right: [],
        },
        progress: {
          eye: firstEye,
          index: 0,
          ring: 0,
          ringErrors: 0,
          canGoNextRound: false,
          canGoPrev: false,
          canShowResults: false,
          showNotice: null,
          prevNotice: null,
        },
      };

      const logmars = testConfiguration.logmar;

      const creditCardRatio = state.creditCardWidth / creditCardDimensions.width;

      const testingDistance = testConfiguration.distance;

      for (let i = 0; i < state.roundsPerEye; i++) {
        let logmarConfiguration = logmars[i];

        const { value: logmar, rings: ringCount } = logmarConfiguration;

        const {
          symbolSize,
          paddingAroundSymbol,
          borderWidthAroundAllSymbols,
          widthAllSymbolsAndBorder,
          heightAllSymbolsAndBorder,
          progressBulletSize,
          progressBulletSpacingFromSymbol,
          marginAroundBox,
        } = calculateSizesForLogmar(logmar, testingDistance, creditCardRatio, ringCount);

        console.log(
          'calculate size',
          calculateSizesForLogmar(logmar, testingDistance, creditCardRatio, ringCount)
        );

        const sizes_ = {
          symbolSize,
          widthAllSymbolsAndBorder,
          heightAllSymbolsAndBorder,
          paddingAroundSymbol,
          progressBulletSpacingFromSymbol: progressBulletSpacingFromSymbol,
          progressBulletSize: progressBulletSize,
          border: {
            widthAroundAllSymbols: borderWidthAroundAllSymbols,
            marginAroundBox,
          },
        };

        state.testData.rounds.left[i] = {
          logmar,
          sizes: JSON.parse(JSON.stringify(sizes_)),
          rings: generateRandomRingsOrientation(ringCount),
          results: [],
        };

        state.testData.rounds.right[i] = {
          logmar,
          sizes: JSON.parse(JSON.stringify(sizes_)),
          padding: paddingAroundSymbol,
          rings: generateRandomRingsOrientation(ringCount),
          results: [],
        };
      }

      // biggest logmar - checking width
      let widthNeeded = state.testData.rounds.left[0].sizes.widthAllSymbolsAndBorder;
      if (widthNeeded > window.innerWidth) {
        console.error(`Cannot render biggest test ${widthNeeded} > ${window.innerWidth}`);
        state.currentPage = 'PageProperDevice';
        state.showModal = false;
      }
      // biggest logmar - checking height
      let heightNeeded = state.testData.rounds.left[0].sizes.heightAllSymbolsAndBorder;
      const borderMargin = state.testData.rounds.left[0].sizes.border.marginAroundBox.bottom;
      const heightStatusBox = 0; // Used to be 45
      const marginStatusBox = 20;
      const heightActionsBox = 0;  // used to be 70
      let totalHeightNeeded =
        heightNeeded + borderMargin + heightStatusBox + marginStatusBox + heightActionsBox;
      console.log('|> totalHeightNeeded', totalHeightNeeded);
      if (totalHeightNeeded > window.innerHeight) {
        console.error(`Cannot render biggest test ${heightNeeded} > ${window.innerHeight}`);
        state.currentPage = 'PageProperDevice';
        state.showModal = false;
      }

      // smallest logmar - checking ppi
      let pixelsForLastSymbol =
        state.testData.rounds.left[state.testData.rounds.left.length - 1].sizes.symbolSize;
      if (pixelsForLastSymbol < 3) {
        console.error(`cannot render smaller test ${pixelsForLastSymbol}`);
        state.currentPage = 'PageProperDevice';
        state.showModal = false;
      }
    },

    testGoNext(state, result) {
      const idx = getIndexBasedOnDistance(state.distance);

      const testConfiguration = (state.testMode === testModes.BASIC ? testBasicConfiguration : testAdvancedConfiguration)[idx];

      const logmars = testConfiguration.logmar;

      const progress = state.testData.progress;
      const ringCount = logmars[progress.index].rings;
      /*
            {
                "eye": "right",
                "index": 0,
                "ring": 0,
                "ringErrors": 0,
                "canGoNextRound": false,
                "canGoPrev": false,
                "canShowResults": false,
                "showNotice": null,
                "prevNotice": null
            }
      */

      if (progress.ring >= ringCount) {
        return;
      }

      const round = state.testData.rounds[progress.eye][progress.index];
      /*
            {
                "logmar": 1,
                "sizes": {
                    "symbolSize": 68,
                    "width": 544,
                    "padding": 34,
                    "progress": 85,
                    "progressSize": 24,
                    "borderWidth": 14
                },
                "padding": 34,
                "rings": [
                    0,
                    0,
                    2,
                    1
                ],
                "results": [
                    true,
                    true,
                    true
                ],
                "status": 1
            }
      */

      round.results[progress.ring] = round.rings[progress.ring] === result;
      progress.ringErrors = round.results.filter((v) => v === false).length;
      round.status =
        progress.ringErrors >= maxErrorsPerTest ? roundStatus.FAILED : roundStatus.SUCCESS;

      progress.canGoPrev = true;
      progress.ring++;

      // Check if the current ring is not the last one, we continue
      if (progress.ring < ringCount) {
        return;
      }

      // If the current index is lower than the roundsPerEye, we can go to the next round
      if (progress.index < state.roundsPerEye - 1) {
        progress.canGoNextRound = true;
        return;
      }

      // If we are at the second eye, then the test is over
      const isSecondEye = progress.eye === secondEye;
      if (isSecondEye) {
        progress.canShowResults = true;
      } else {
        progress.canGoNextRound = true;
      }
    },

    testGoPrev(state) {
      if (state.testData.progress.ring === 0) {
        return;
      }
      state.testData.progress.ring--;
      state.testData.progress.canGoNextRound = false;
      state.testData.progress.canShowResults = false;

      if (state.testData.progress.ring === 0) {
        state.testData.progress.canGoPrev = false;
      }
    },

    testGoNextRound(state) {
      let p = state.testData.progress;
      if (!p.canGoNextRound) {
        return;
      }

      p.canGoNextRound = false;
      p.canGoPrev = false;
      p.ring = 0;

      /* Shift eye or end test if more than 2 error within the same rings/step */
      if (state.testData.rounds[p.eye][p.index].status === roundStatus.FAILED) {
        p.showNotice = noticeTypes.TOOMANYERRORS;
        if (p.eye === secondEye) {
          p.canShowResults = true;
          p.index = state.roundsPerEye - 1;
        } else {
          p.eye = secondEye;
          p.index = 0;
        }
        return;
      }

      if (1 + p.index >= state.roundsPerEye) {
        p.index = 0;
        if (p.eye === secondEye) {
          p.showResults = true;
        } else {
          p.showNotice = noticeTypes.EYESHIFT;
          p.eye = secondEye;
        }
      } else {
        p.index++;
      }
    },

    dismissNotice(state) {
      state.testData.progress.showNotice = null;
      state.testData.progress.ringErrors = 0;
    },
  },
});

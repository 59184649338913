<template>

  <div class="text-center">
    <h2 class="h-2 text-center">{{ $t('global.warning') }}</h2>
    <p class="body" v-html="$t('global.going-to-about-page')">

    </p>
  </div>
</template>

<script>

export default {

  components: {},
  props: [],
  data() {
    return {
    };
  },
  methods: {},
};
</script>

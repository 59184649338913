export default {
  "page-results": {
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Résultats à 2m :</b> œil gauche ", _interpolate(_named("leftResult")), "\"/> et œil droit <variable name=\"", _interpolate(_named("rightResult"))])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeil gauche"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explication"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un optométriste ne peut pas vous aider à voir clairement, il est important que vous sachiez pourquoi.<br />Contactez toujours un ophtalmologiste pour le savoir."])},
    "normal-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision normale"])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Merci d'avoir effectué un test numérique d'acuité visuelle sur eye-sight.org.<br>Consultez toujours un opticien ou un spécialiste des soins oculaires pour toute question médicale ou inquiétude concernant votre vision.<br>"])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un nouveau test"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la maison"])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez maintenant terminé votre test de vision de la lecture\n"])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une mauvaise vision et vous avez intérêt à passer un test de vision chez un optométriste."])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Résultats à 40 cm :</b> œil gauche ", _interpolate(_named("leftResult")), "\"/> et œil droit <variable name=\"", _interpolate(_named("rightResult"))])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre vision est ci-dessous"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez correctement identifié ", _interpolate(_named("successful")), "\"/> des symboles <variable name=\"", _interpolate(_named("steps")), "\"/> à une distance de <variable name=\"", _interpolate(_named("distanceKey")), "\"/>.<br>La plupart des gens sont capables d'identifier <variable name=\"", _interpolate(_named("normalVisionSteps")), "\"/> des symboles <variable name=\"", _interpolate(_named("steps")), "."])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une vision normale"])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une vision acceptable"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer un nouveau test"])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeil droit"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez maintenant terminé votre test de vision de loin\n"])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Vous auriez probablement intérêt à prendre contact avec un opticien. Si l'opticien n'est pas en mesure de vous aider à voir clairement, il est important que vous en découvriez la raison. Un spécialiste des soins oculaires (ophtalmologiste) peut vous aider.<br><br>Consultez toujours un opticien ou un spécialiste des soins oculaires pour toute question médicale ou préoccupation concernant votre vision."])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Vous avez maintenant terminé votre test de vision de loin.</h3><p>Vous avez répondu que vous voyiez clairement ", _interpolate(_named("successful")), "\"/> des symboles <variable name=\"", _interpolate(_named("steps")), " à une distance de 2 mètres.<br><em class='small'> D</em>'autres peuvent voir clairement 40 des 40 symboles à la même distance<sup>.*</sup><br> *<em class='small'> Source ; Lisbeth Jelveh Sandfeld, PhD, senior consultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11) : e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Merci d'avoir effectué un test numérique d'acuité visuelle sur eye-sight.org. Si vous n'êtes pas sûr d'avoir une bonne vue, commencez par contacter un opticien. S'il n'est pas en mesure de vous aider à voir clairement, il est important que vous en découvriez la raison. Un spécialiste des soins oculaires (ophtalmologue) peut vous aider à cet égard.<br><p>Ce test de la vue :</p><ul><li>N'est pas un dispositif médical.</li><li>Ne convient pas à des fins médicales.</li>Est<li>destiné à des personnes en bonne santé.</li>N<li>'est pas destiné à remplacer un test de la vue effectué par un professionnel.</li>Ne<li>recueille aucune donnée personnelle identifiable vous concernant, ou concernant </li> toute autre personne.</ol><p>Consultez toujours un opticien ou un spécialiste des soins oculaires pour toute question médicale ou préoccupation concernant votre vision.</p>"])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Vous avez maintenant terminé votre test de vision de lecture.</h3><p>Vous avez répondu que vous voyiez clairement ", _interpolate(_named("successful")), "\"/> des symboles <variable name=\"", _interpolate(_named("steps")), " à une distance de 40 cm.<br><em class='small'> D</em>'autres peuvent voir clairement 36 des 40 symboles à la même distance<sup>.*</sup><br> *<em class='small'> Source ; Lisbeth Jelveh Sandfeld, PhD, senior consultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11) : e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])}
  },
  "page-how-it-works": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment cela fonctionne-t-il ?"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrage"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer l'ouverture de l'anneau à l'aide de la <span class='bold'>flèche directionnelle du clavier</span> ou cliquer sur <span class='bold'>les boutons</span> ou <span class='bold'>swiping</span>"])}
  },
  "global": {
    "screen-warning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre écran est trop étroit pour effectuer le test d'acuité visuelle numérique DIY. "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez un smartphone et que vous le tenez verticalement, essayez de l'incliner horizontalement."])}
    },
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la distance"])},
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer en mode de base"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'essentiel"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire l'instruction"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre navigateur actuel n'est pas pris en charge. Veuillez utiliser l'un des navigateurs suivants :<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome pour iOS ou Android"])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas utiliser la version avancée sur mobile"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couvrez votre œil droit"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Test de vue"])])},
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couvrez votre œil gauche"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez dans l'ouverture de l'anneau à l'aide de la touche fléchée, des boutons-clics ou du swiping."])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigateur incompatible"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 mètres"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuster l'écran"])},
    "about-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the test"])},
    "about-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the result"])},
    "going-to-about-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are going to About page,  if you join this page, you will have to begin again from the beginning"])},
    "before-you-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'essentiel"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "taking-the-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez dans l'ouverture de l'anneau à l'aide de la touche fléchée, des boutons-clics ou du swiping."])}
  },
  "page-scale": {
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus grand +1"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus petit -5"])},
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Plus grand</p>"])},
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Plus petit</p>"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus grand +5"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de la vue"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus petit -1"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your mobile phone on a table.</li><li>Place a credit card sized card on the screen.</li><li>Place the narrow side of the card between the crop marks.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Ajuster la taille de l'écran</h2><ol><li>Placez une carte standard sur l'écran à l'intérieur des repères de coupe.</li><li>Ajustez la taille de l'écran à l'aide des boutons.</li><li>Ajustez les repères de coupe de manière à ce qu'ils correspondent à la largeur de votre carte de crédit.</li></ol>"])}
  },
  "page-distance": {
    "info": {
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instructions pour le test de vision de la lecture</h3><p>Vous pouvez tester votre vision de la lecture seul.<br><span class='underline'>Comment tester votre vision de la lecture</span>:</p><div class='first'><div class='text'><p>1. Placez l'écran à 40 cm de vos yeux. Mesurez la distance avec précision.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"/>\"></div></div><div class='second'><div class='text'><p>2. Le test montre quatre cercles à la fois en onze tours. L'ouverture des cercles est-elle dirigée vers le haut, vers le bas, vers la gauche ou vers la droite ? Saisissez vos réponses en glissant la souris, en utilisant les touches fléchées ou en cliquant sur les boutons.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("direction")), "\"/>\"></div></div><div class='third'><div class='text'><p>3. Testez un œil à la fois.</p></div></div><div class='fourth'><div class='text'><p>4. Commencez par couvrir votre œil <strong>gauche</strong>.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Appuyez sur \"Continuer\" lorsque vous êtes prêt.</p>"])},
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instructions pour le test de vision de loin</h3><p>Vous aurez besoin d'une personne pour tester votre vision de loin.<br><span class='underline'>Comment tester votre vision de loin</span>.</p><div class='first'><div class='text'><p>1. Placez votre écran à 2 mètres de vos yeux. Mesurez la distance avec précision. Votre assistant doit se tenir près de l'écran.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"/>\"></div></div><div class='second'><div class='text'><p>2. Le test montre quatre cercles à la fois en douze tours. L'ouverture des cercles est-elle dirigée vers le haut, vers le bas, vers la gauche ou vers la droite ? Votre assistant doit entrer vos réponses en glissant, en utilisant les touches fléchées ou en cliquant sur les boutons.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("direction")), "\"/>\"></div></div><div class='third'><div class='text'><p>3. Testez un œil à la fois.</p></div></div><div class='fourth'><div class='text'><p>4. Commencez par couvrir votre œil <strong>gauche</strong>.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Appuyez sur \"Test\" lorsque vous êtes tous les deux prêts.</p>"])}
    },
    "select-distance": {
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longue distance avec un assistant<br>environ 2 mètres"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ 2 mètres"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance de lecture par soi-même<br>environ 40 cm"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ 40 cm"])}
    },
    "set": {
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Sélectionner la distanceVous</h2>pouvez tester votre vue à deux distances"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de la vue"])}
  },
  "page-checklist": {
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je porte mes lunettes (si vous portez des lunettes)"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, je suis prêt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière instruction..."])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis à l'intérieur"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je maintiens l'écran à l'horizontale"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je m'en tiens à l'œil gauche"])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai complètement augmenté la luminosité"])},
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai mesuré que mes yeux sont à 40 cm / 2 mètres de l'écran"])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai personnalisé l'écran en fonction d'une carte de crédit"])}
  },
  "page-test": {
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laisser"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de la vue"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain tour"])},
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer d'œil"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bas"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gauche"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la dernière"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Couvrez votre œil droit et maintenez l'écran ", _interpolate(_named("distance")), " loin de vos yeux."])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut de la page"])},
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Essais</strong>"])},
    "notice-eye-shift": {
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Vous pouvez maintenant tester votre œil gauche.<br>Couvrez votre œil <strong>droit</strong>.<br>Éloignez l'écran ", _interpolate(_named("distance")), " de vos yeux<br>Appuyez sur \"Next round\".</p>"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez maintenant testé votre œil droit."])}
    },
    "notice-too-many-errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deux erreurs"])},
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Vous avez testé votre œil droit.<br>Vous pouvez maintenant tester votre œil gauche.<br>Couvrez votre œil <strong>droit</strong>.<br>Éloignez l'écran ", _interpolate(_named("distance")), " de vos yeux<br>Appuyez sur \"Next round\".</p>"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Vous avez maintenant testé votre œil gauche et terminé le test. <br>Appuyez sur \"Afficher les résultats\".</p>"])}
    },
    "made-two-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez commis deux erreurs."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrage"])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par couvrir votre œil gauche"])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de l'exposition"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit"])}
  },
  "page-keep-screen-horizontal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez garder votre écran horizontal pendant le test."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenir l'écran horizontal"])}
  },
  "page-2-metres": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez besoin d'un assistant pour le test longue distance"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenez-vous à 2 mètres de votre ordinateur, votre assistant doit se tenir à côté de l'écran et il vous indiquera les réponses."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
  },
  "page-intro": {
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Visitez le site web eye-sight.org pour en savoir plus.</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen Værn om Synet<br/>Ny Kongensgade 20<br/>1557 Copenhagen V"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens<br />test de vision gratuit et en ligne à faire soi-même"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de la vue"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrage"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Classification :</span><br/>\nClasse I selon le règlement sur les dispositifs médicaux MDR 2017/745, annexe VIII, règle 11.<br/><br/>\n\nNous déclarons que le système de test d'acuité visuelle numérique DIY et portant le marquage CE est conforme aux exigences générales de sécurité et de performance du règlement sur les dispositifs médicaux MDR 2017/745 Annexe I. Les normes harmonisées applicables ont été utilisées pour démontrer la sécurité et l'efficacité du dispositif lorsqu'il est utilisé conformément à sa destination et aux instructions qui l'accompagnent.<br/><br/>\n\nLes normes suivantes ont été utilisées :<br/><br/>\n\n<span class=\"body-bold\">Fabrication</span>: EN ISO 13485:2016<br/>\n<span class=\"body-bold\">Gestion des risques</span>: EN ISO 14971:2019<br/>\n<span class=\"body-bold\">Cycle de vie des logiciels</span>: EN 62304:2006/A1:2015<br/><br/>\n\nChaque version du logiciel est publiée conformément aux enregistrements certifiant la conformité aux spécifications."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontak", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])}
  },
  "page-keep-them": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous portez des lunettes ou des lentilles de contact, conservez-les."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gardez-les"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
  },
  "page-in-full-brightness": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poursuivre"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglez le rétroéclairage de l'écran à fond."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En pleine lumière"])}
  },
  "page-before-starting-test": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenez une <span class=\"bold\">carte</span> standard, elle vous sera indispensable pour l'épreuve."])},
    "take-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de commencer le test"])}
  },
  "page-measure-40-cm": {
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placez votre écran à 2 mètres de vos yeux"])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce que 40 cm ?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesurer la distance<br>à l'aide d'une règle"])},
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placez l'écran à 40 cm de vos yeux"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 feuilles de papier A4"])}
  },
  "page-proper-dimensions": {
    "proper-device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille réelle de l'écran de votre appareil n'est pas prise en charge<br />La taille de votre fenêtre doit être d'au moins 24 cm x 15 cm<br />Veuillez utiliser un appareil compatible."])}
  },
  "page-ce": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontak", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00d8jenforeningen V00e6rn om Synet<br/>Ny Kongensgade 20<br/>1557 Copenhagen V"])},
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Visit the eye-sight.org website to learn more</a>"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyesight test"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00d8jenforeningens<br />free, online do-it-yourself vision test"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Classification:</span><br/>Class I according to the Medical Device Regulation MDR 2017/745, Annex VIII, Rule 11.<br/><br/>We declare that the DIY Digital Visual Acuity Test System and bearing CE marking complies with the general safety and performance requirements of the Medical Device Regulation MDR 2017/745 Annex I. Applicable harmonized standards have been used to demonstrate the safety and effectiveness of the device when used in accordance with its intended use and the accompanying instructions.<br/><br/>The following standards were used:<br/><br/><span class=\"body-bold\">Manufacturing</span>: EN ISO 13485:2016<br/><span class=\"body-bold\">Risk management</span>: EN ISO 14971:2019<br/><span class=\"body-bold\">Software lifecycle</span>: EN 62304:2006/A1:2015<br/><br/>Each version of the software is released in accordance with records certifying compliance with the specifications"])}
  },
  "about-page": {
    "always-respect-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always keep the indicated distance between the screen on which you are taking the test and your eyes. A different distance – shorter or longer – will impact your result thus making it imprecise. This test can be taken at 40 cm (reading vision) and 2 meters (distance vision).<br /><br />You test one eye at a time. It is important that you cover the other eye as instructed during the test."])},
    "how-it-works-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the test, you are to identify an opening in a series of C-symbols. The opening in the C-symbol can be either in the top, the bottom, to the right or to the left. Depending on the device, use the arrow keys, swipe-function, or other means to give your answers. The visual acuity is measured in Snellen fractions ranging from 6/6 (perfect vision) to 6/60 (social blindness). The smaller symbols you can correctly identify the better vision you have."])},
    "declaration-of-conformity-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark´s free, online do-it-yourself eyesight test:<br />Declaration of conformity"])},
    "what-it-means-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What it means"])},
    "basic-mode-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight has divided the Snellen fractions into three categories: Normal vision, acceptable vision and impaired vision.<br /><br />Normal vision (marked with green) includes results between 6/6 and 6/9. <br />Acceptable vision (marked with yellow) includes results between 6/10 and 6/12.<br />Impaired vision (marked with red) includes results between 6/15 and 6/60."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the test and your test results"])},
    "how-it-works-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "about-test-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight test can assess your visual acuity at two distances: 40 cm (reading vision) and 2 meters (distance vision). It is intended to evaluate whether you will benefit from a full eyesight test at an optometrist. The current version of the test is intended to be used by Danish resident only.<br /><br />The eyesight test is based upon the ETDRS chart that you can meet at your optometrist or ophthalmologist. Using the C-symbols in various sizes, the test is designed to measure your visual acuity – that is your ability to see clearly. The size and design are based on system of the ETDRS charts."])},
    "what-it-means-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The result of your test is given as either normal, acceptable or impaired vison.<br /><br />If the result is normal vision, you should do nothing more.<br />If the result is acceptable or impaired, we recommend that you consult an optometrist. You will benefit from taking a professional eyesight test at an optometrist. If an optometrist cannot help you to see clearly, it is important that you find out why. Always contact an eye care specialist (ophthalmologist) to find out what is causing your inability to see clearly."])},
    "declaration-of-conformity-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We declare that Fight for Sight, Denmark’s free, online do-it-yourself eyesight test bearing CE marking complies with the general safety and performance requirements of the Medical Device Regulation MDR 2017/745 Annex I. Applicable harmonized standards have been used to demonstrate the safety and effectiveness of the device when used in accordance with its intended use and the accompanying instructions.<br/><br/>The following standards were used:<br/><br/><span class=\"body-bold\">Manufacturing</span>: EN ISO 13485:2016<br/><span class=\"body-bold\">Risk management</span>: EN ISO 14971:2019<br/><span class=\"body-bold\">Software lifecycle</span>: EN 62304:2006/A1:2015<br/><br/>Each version of the software is released in accordance with records certifying compliance with the specifications."])},
    "advanced-mode-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight for Sight, Denmark’s free, online do-it-yourself eyesight has divided the Snellen fractions into three categories: Normal vision, acceptable vision and impaired vision. <br /><br />Normal vision (marked with green) includes the following results: 6/6, 6/7, 6/8 and 6/9<br/>Acceptable vision (marked with yellow) includes the following results: 6/10 and 6/12<br />Impaired vision (marked with red) includes the following results: 6/15, 6/20, 6/30 and 6/60"])}
  }
}
<template>
  <div class="page colored-centered-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: [],
    };
  },
  methods: {},
};
</script>

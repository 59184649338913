import {createApp} from 'vue';
import App from './App.vue';
import i18n from './i18n';
import store from './store.js';
import Vue3TouchEvents from 'vue3-touch-events';
import VueScreen from 'vue-screen';
import * as VueRouter from 'vue-router';
import analyticsStep from "@/utils/analyticsStep";
import PageAbout from "@/components/pages/PageAbout.vue";
import PageIntro from "@/components/pages/PageIntro.vue";
import Vue3Lottie from 'vue3-lottie'

const app = createApp(App);

const routes = [
    { path: '/', component: PageIntro, name: 'intro' },
    { path: '/about', component: PageAbout, name: 'about' },
]

export const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
})

window.App = app
    .use(i18n)
    .use(store)
    .use(Vue3TouchEvents)
    .use(analyticsStep)
    .use(Vue3Lottie)
    .use(
        VueScreen /*, {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1280,
        xl: 1280,
    }*/
    )
    .use(router)
    .mount('#app');

export default {
    router,
}

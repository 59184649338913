import Airtable from 'airtable';
import moment from "moment";

Airtable.configure({
    endpointUrl: process.env.VUE_APP_API_URL,
    apiKey: process.env.VUE_APP_AIRTABLE_API_KEY,
});
export const base = Airtable.base(process.env.VUE_APP_AIRTABLE_BASE_ID);

export const createEntry = (store, initMainField = true) => {
    base('results').create(
        [
            {
                fields: {
                    language: store.state.language,
                    checkboxes: 'false',
                    distance: "N/A",
                    'left-results': 'N/A',
                    'right-results': 'N/A',
                    'start-full-test': moment(),
                    'last-step-completed': store.state.currentPathCompleted,
                    'referrer': document.referrer,
                },
            },
        ],
        function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            store.commit("setEntryID", records[0].id);
            if (initMainField) {
                store.commit("setMainFieldID", records[0].fields['main-field']);
            } else {
                base('results').update(
                    [
                        {
                            id: records[0].id,
                            fields: {
                                'restart-init-main-field': store.state.mainFieldID,
                            },
                        },
                    ],
                    function (err) {
                        if (err) {
                            console.error(err);
                            return;
                        }
                    }
                );
            }
        }
    );
}

export const updateEntry = (store, field) => {
    if (field.includes("7")) {
        base('results').update(
            [
                {
                    id: store.state.entryID,
                    fields: {
                        'last-step-completed': store.state.currentPathCompleted,
                        distance: store.state.distance === 0 ? '40cm' : '2m',
                        [field]: moment(),
                    },
                },
            ],
            function (err) {
                if (err) {
                    console.error(err);
                    return;
                }
            }
        );
        return;
    }

    if (field === "8-checklist") {
        base('results').update(
            [
                {
                    id: store.state.entryID,
                    fields: {
                        'last-step-completed': store.state.currentPathCompleted,
                        checkboxes: 'true',
                        '8-checklist': moment(),
                    },
                },
            ],
            function (err) {
                if (err) {
                    console.error(err);
                    return;
                }
            }
        );
        return;
    }
    base('results').update(
        [
            {
                id: store.state.entryID,
                fields: {
                    [field]: moment(),
                    'last-step-completed': store.state.currentPathCompleted,
                },
            },
        ],
        function (err) {
            if (err) {
                console.error(err);
                return;
            }
        }
    );
}

export const addResultEntry = (store, results) => {
    base('results').update(
        [
            {
                id: store.state.entryID,
                fields: {
                    'duration-test': store.state.duration_test,
                    rounds: store.state.roundsPerEye,
                    'left-results': results.leftResult ?? "N/A",
                    'right-results': results.rightResult ?? "N/A",
                    'test-completed': moment()
                },
            },
        ],
        function (err) {
            if (err) {
                console.error(err);
                return;
            }
        }
    );
};

export default {
  "page-checklist": {
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosím okuliare (ak nosíte okuliare)"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, som pripravený"])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úplne som zvýšil jas"])},
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeral som, že moje oči sú 40 cm / 2 metre od obrazovky"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledný pokyn..."])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som vo vnútri"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazovku držím vo vodorovnej polohe"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držím sa pri ľavom oku"])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazovku som prispôsobil na základe kreditnej karty"])}
  },
  "page-test": {
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nechajte"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšie kolo"])},
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeňte svoje oko"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dole"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vľavo"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť posledný"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zakryte si pravé oko a držte obrazovku ", _interpolate(_named("distance")), " ďalej od očí."])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahor"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vpravo"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz ste otestovali pravé oko."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Teraz si môžete otestovať ľavé oko.<br>Zakryte si <strong>pravé</strong> oko.<br>Držte obrazovku ", _interpolate(_named("distance")), " ďalej od očí.<br>Stlačte \"Next round\".</p>"])}
    },
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Testovanie</strong>"])},
    "notice-too-many-errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dve chyby"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Teraz ste otestovali svoje ľavé oko a dokončili test. <br>Stlačte tlačidlo \"Zobraziť výsledky\".</p>"])},
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Teraz ste si otestovali pravé oko.<br>Teraz si môžete otestovať ľavé oko.<br>Zakryte si <strong>pravé</strong> oko.<br>Držte obrazovku ", _interpolate(_named("distance")), " ďalej od očí.<br>Stlačte \"Next round\".</p>"])}
    },
    "made-two-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urobili ste dve chyby."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štart"])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začnite tým, že si zakryjete ľavé oko"])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť výsledky"])}
  },
  "global": {
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte vzdialenosť"])},
    "screen-warning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša obrazovka je príliš úzka na vykonanie testu digitálnej zrakovej ostrosti. "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak používate smartfón a držíte ho vertikálne, skúste ho nakloniť horizontálne."])}
    },
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prechod do základného režimu"])},
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakryte si ľavé oko"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do otvoru krúžku vstúpite pomocou klávesov so šípkami, kliknutím na tlačidlá alebo potiahnutím prstom."])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základné informácie"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prečítajte si inštrukcie"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš aktuálny prehliadač nie je podporovaný. Použite jeden z nasledujúcich prehliadačov:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome pre iOS alebo Android"])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilú verziu nemôžete používať v mobilnom telefóne"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakryte si pravé oko"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Test zraku"])])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nekompatibilný prehliadač"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 metre"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledky"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenie obrazovky"])}
  },
  "page-scale": {
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menšie -5"])},
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Väčšie</p>"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väčší +1"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])},
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Menšie</p>"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väčší +5"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripravené"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menšie -1"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Nastavenie veľkosti obrazovky</h2><ol><li>Umiestnite štandardnú kartu na obrazovku do vnútra orezaných značiek.</li><li>Pomocou tlačidiel upravte veľkosť obrazovky.</li><li>Upravte značky orezania tak, aby sa zarovnali so šírkou kreditnej karty</li></ol>"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your mobile phone on a table.</li><li>Place a credit card sized card on the screen.</li><li>Place the narrow side of the card between the crop marks.</li></ol>"])}
  },
  "page-results": {
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ďakujeme, že ste si urobili digitálny test zrakovej ostrosti na stránke eye-sight.org.<br>Vždy sa poraďte s optikom alebo očným špecialistom v prípade akýchkoľvek zdravotných otázok alebo obáv týkajúcich sa vášho zraku.<br>"])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber nového testu"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak vám optometrista nedokáže pomôcť vidieť jasne, je dôležité, aby ste zistili prečo.<br />Vždy sa obráťte na oftalmológa, aby vám to vysvetlil."])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Výsledky na 2 m</b>: ľavé oko ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz ste dokončili test videnia na čítanie\n"])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Výsledky pri 40 cm</b>: ľavé oko ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaša vízia je nižšie"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iné"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vy"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Správne ste identifikovali symboly ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols at a ", _interpolate(_named("distanceKey")), " distance.<br>Most people are able to identify ", _interpolate(_named("normalVisionSteps")), " out of the ", _interpolate(_named("steps")), "."])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz ste dokončili test videnia do diaľky\n"])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Pravdepodobne by vám pomohlo, keby ste sa obrátili na očného lekára. Ak vám optik nedokáže pomôcť vidieť jasne, je dôležité, aby ste zistili, prečo. S tým vám môže pomôcť očný špecialista (oftalmológ).<br><br>Vždy sa poraďte s optikom alebo očným špecialistom v prípade akýchkoľvek zdravotných otázok alebo obáv týkajúcich sa vášho videnia."])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Teraz ste dokončili test videnia do diaľky.</h3><p>Odpovedali ste, že ste jasne videli ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbolov na vzdialenosť 2 metrov.<br>Ostatní vidia jasne 40 zo 40 symbolov na rovnakú vzdialenosť<sup>.*</sup><br> *<em class='small'> Zdroj; Lisbeth Jelveh Sandfeld, PhD, senior konzultantka. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials (Zrak u futbalových funkcionárov na vysokej úrovni). PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ďakujeme, že ste si urobili digitálny test zrakovej ostrosti na stránke eye-sight.org. Ak si nie ste istí, či máte dobrý zrak, začnite tým, že sa obrátite na očného lekára. Ak vám optik nedokáže pomôcť vidieť jasne, je dôležité, aby ste zistili, prečo. S tým vám môže pomôcť očný špecialista (oftalmológ).<br><p>Tento test zraku:</p><ul><li>Nie je zdravotníckou pomôckou.</li><li>nie je vhodný na žiadne lekárske účely.</li><li>je určený pre zdravých jedincov.</li><li>nemá nahradiť profesionálne vyšetrenie zraku.</li><li>nezhromažďuje žiadne osobné údaje o vás ani o nikom inom </li>.</ol><p>Akékoľvek lekárske otázky alebo obavy týkajúce sa vášho zraku vždy konzultujte s optikom alebo očným špecialistom.</p>"])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Teraz ste dokončili test videnia na čítanie.</h3><p>Odpovedali ste, že ste jasne videli ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbolov na vzdialenosť 40 cm.<br>Ostatní vidia jasne 36 zo 40 symbolov na rovnakú vzdialenosť<sup>.*</sup><br> *<em class='small'> Zdroj; Lisbeth Jelveh Sandfeld, PhD, senior konzultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])}
  },
  "page-distance": {
    "set": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Zvoľte vzdialenosťMôžete</h2>si otestovať zrak z dvoch vzdialeností"])},
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť"])}
    },
    "select-distance": {
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na dlhú vzdialenosť s pomocníkom<br>asi 2 metre"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začiatok 2 metre"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čítacia vzdialenosť podľa seba<br>asi 40 cm"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začiatok 40 cm"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrané"])}
    },
    "info": {
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť"])},
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Pokyny na test videnia do diaľky</h3><p>Na testovanie zraku do diaľky budete potrebovať pomocníka.<br><span class='underline'>Ako si otestovať zrak do diaľky.</span></p><div class='first'><div class='text'><p>1. Umiestnite si obrazovku 2 metre od očí. Presne zmerajte vzdialenosť. Váš pomocník by mal stáť pri obrazovke.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in twelve rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Your helper should enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Keď ste obaja pripravení, stlačte tlačidlo \"Test\".</p>"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Pokyny na test čítania zraku</h3><p>Zrak na čítanie si môžete otestovať sami.<br><span class='underline'>Ako si otestovať zrak na čítanie:</span></p><div class='first'><div class='text'><p>1. Umiestnite obrazovku 40 cm od vašich očí. Presne zmerajte vzdialenosť.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in eleven rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Keď ste pripravení, stlačte tlačidlo \"Pokračovať\".</p>"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])}
  },
  "page-keep-screen-horizontal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počas testu musíte mať obrazovku vo vodorovnej polohe."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udržujte obrazovku vo vodorovnej polohe"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])}
  },
  "page-intro": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Klasifikácia:</span><br/>\nTrieda I podľa nariadenia o zdravotníckych pomôckach MDR 2017/745, príloha VIII, pravidlo 11.<br/><br/>\n\nVyhlasujeme, že digitálny systém na testovanie zrakovej ostrosti DIY a s označením CE spĺňa všeobecné požiadavky na bezpečnosť a výkon podľa nariadenia o zdravotníckych pomôckach MDR 2017/745, príloha I. Na preukázanie bezpečnosti a účinnosti pomôcky pri jej používaní v súlade s jej určeným použitím a priloženými pokynmi boli použité platné harmonizované normy.<br/><br/>\n\nBoli použité tieto normy:<br/><br/>\n\n<span class=\"body-bold\">Výroba</span>: EN ISO 13485:2016<br/>\n<span class=\"body-bold\">Riadenie rizík</span>: EN ISO 14971:2019<br/>\n<span class=\"body-bold\">Životný cyklus softvéru</span>: EN 62304:2006/A1:2015<br/><br/>\n\nKaždá verzia softvéru sa vydáva v súlade so záznamami potvrdzujúcimi zhodu so špecifikáciami"])},
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Navštívte webovú stránku eye-sight.org a dozviete sa viac</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen Værn om Synet<br/>Ny Kongensgade 20<br/>1557 Copenhagen V"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens<br />bezplatný online test zraku"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štart"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontak", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])}
  },
  "page-2-metres": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na test na dlhé vzdialenosti potrebujete pomocníka"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buďte 2 metre od počítača, váš pomocník by mal stáť vedľa obrazovky a bude vám ukazovať odpovede."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])}
  },
  "page-how-it-works": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štart"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako to funguje"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte otvorenie krúžku pomocou <span class='bold'>smerovej šípky na klávesnici</span> alebo <span class='bold'>kliknite na tlačidlá</span> alebo <span class='bold'>potiahnite prstom</span>."])}
  },
  "page-keep-them": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak nosíte okuliare alebo kontaktné šošovky, nechajte si ich."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nechajte si ich"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])}
  },
  "page-in-full-brightness": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte podsvietenie obrazovky úplne nahor."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V plnom jase"])}
  },
  "page-before-starting-test": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pred začatím testu"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezmite si štandardnú <span class=\"bold\">kartu</span>, ktorá bude pre vás pri teste nevyhnutná."])},
    "take-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])}
  },
  "page-measure-40-cm": {
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiestnite obrazovku 2 metre od očí"])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo je to 40 cm ?"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmerajte vzdialenosť<br>pomocou pravítka"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 kusy papiera A4"])},
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiestnite obrazovku 40 cm od očí"])}
  },
  "page-proper-dimensions": {
    "proper-device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skutočná veľkosť obrazovky vášho zariadenia nie je podporovaná<br />Mali by ste mať okno s veľkosťou min. 24 cm x 15 cm<br />Prepnite na kompatibilné zariadenie"])}
  }
}
<template>
  <div class="left-or-right-eye-container text-center" v-if="eye === firstEye">
    <h2 class="h-2 text-center">{{ $t('page-test.change-eye') }}</h2>
    <p class="body" v-html="$t('page-test.made-two-errors', {distance: this.getDistance()})"></p>
    <img class="margin-right-l" :src="coverRightEye" alt="Image"/>
  </div>
</template>

<script>
import coverLeftEye from '@/assets/img/cover-left.svg';
import coverRightEye from '@/assets/img/cover-right.svg';
import {firstEye, secondEye} from "@/constants";
import {getIndexBasedOnDistance} from "@/utils/utils";
import i18n from "@/i18n";

export default {
  components: {},
  props: ['eye'],
  data() {
    return {
      coverLeftEye,
      coverRightEye,
      firstEye,
      secondEye,
    };
  },
  methods: {
    getDistance() {
      return getIndexBasedOnDistance(this.$store.state.distance) === '40cm' ? i18n.global.t('global.distance-40-cm') : i18n.global.t('global.distance-2-m');
    }
  },
};
</script>

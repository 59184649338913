<template>
  <component :is="'Modal'"/>
  <div class="device-orientation screen-warning" v-show="this.showScreenWarning">
    <h3 class="title">
      {{ $t('global.screen-warning.title') }}
    </h3>
    <div class="description">
      {{ $t('global.screen-warning.description') }}
    </div>
  </div>
  <div class="page-container" v-show="!this.showScreenWarning">
    <TopNavbar v-show="!this.showScreenWarning && this.showNavBar" :pageTitle="getCurrentPageTitle"/>
    <div class="contents">
      <router-view v-if="this.$route.path === '/about'"></router-view>
      <component :is="getCurrentPage" v-else></component>
    </div>
  </div>
  <GlobalStyle/>
</template>

<script>
import {defineAsyncComponent} from 'vue';
import GlobalStyle from './styles/global.scss';
import TopNavbar from './components/TopNavbar.vue';
import Modal from "@/components/Modal";
import {useI18n} from 'vue-i18n';
import {browserTypes, deviceTypes, testModes} from "@/constants";
import IncompatibleBrowser from "@/components/IncompatibleBrowser";
import {getBrowser, getDeviceType} from "@/utils/utils";
import CantAccessAdvancedOnMobile from "@/components/CantAccessAdvancedOnMobile.vue";
import {router} from "@/main";


const pages = {
  PageIntro: 'page-intro',
  PageBeforeStartingTest: 'page-before-starting-test',
  PageKeepThem: 'page-keep-them',
  PageInFullBrightness: 'page-full-brightness',
  PageKeepScreenHorizontal: 'page-screen-horizontal',
  PageScale: 'page-scale',
  PageProperDevice: 'page-proper-device',
  PageDistance: 'page-distance',
  PageMeasure40cm: 'page-measure-40-cm-ruler',
  Page2Metres: 'page-2-metres',
  PageHowItWorks: 'page-how-it-works',
  PageChecklist: 'page-checklist',
  PageTest: 'page-test',
  PageResults: 'page-results',
  PageAbout: 'page-about',
};


const pagesNotToShowNavbar = [
  'PageIntro',
  'PageProperDevice',
];

let components = {
  GlobalStyle,
  TopNavbar,
  Modal,
};

Object.keys(pages).forEach(function (v) {
  components[v] = defineAsyncComponent(() => import('./components/pages/' + v));
});

let pagesTitles;

export default {
  name: 'App',
  components: components,
  setup() {
    const {t} = useI18n()

    pagesTitles = {
      PageIntro: 'Intro',
      PageBeforeStartingTest: t('global.before-you-start'),
      PageKeepThem: t('global.before-you-start'),
      PageInFullBrightness: t('global.before-you-start'),
      PageKeepScreenHorizontal: t('global.before-you-start'),
      PageScale: t('global.before-you-start'),
      PageDistance: t('global.before-you-start'),
      PageMeasure40cm: t('global.before-you-start'),
      Page2Metres: t('global.before-you-start'),
      PageHowItWorks: t('global.before-you-start'),
      PageChecklist: t('global.before-you-start'),
      PageProperDevice: '',
      PageTest: t('global.taking-the-test'),
      PageResults: t('global.results'),
      PageAbout: t('global.about-test'),
    };
  },
  data() {
    return {
      showScreenWarning: false,
      incompatibleBrowser: false,
      testModes: testModes,
      router,
    };
  },
  computed: {
    getCurrentPage() {
      document.title =
          this.$t('global.app-title') +
          ' | ' +
          this.$t(pages[this.$store.state.currentPage] + '.title');
      return this.$store.state.currentPage;
    },
    getCurrentPageTitle() {
      return this.$t(pagesTitles[this.$store.state.currentPage]);
    },
    showNavBar() {
      return pagesNotToShowNavbar.indexOf(this.getCurrentPage) === -1;
    },
    isMobile() {
      return this.$grid.xs;
    },
  },
  mounted() {
    let path = window.location.pathname;
    if (path === '/about') {
      // Because PageAbout can be accessed directly with /about path and that in that case currentPage equals PageIntro
      // we need to change currentPage to PageAbout
      this.$store.commit('changePage', 'PageAbout');
    }

    this.$store.commit('setLanguage', this.$i18n.locale);
  },
  watch: {
    '$screen.landscape': {
      handler(newVal) {
        setTimeout(() => {
          this.showScreenWarning = !this.$grid.lg && !newVal;
        }, 0);
      },
    },
  },
  methods: {
    setLanguage() {
      document.documentElement.setAttribute('lang', this.$i18n.locale);
    },
    updateScreenWarning() {
      this.showScreenWarning = !this.$grid.lg && !this.$screen.landscape;
    },
    getModeFromUrl() {
      let url = new URL(window.location.href);
      let mode = url.searchParams.get('mode');
      console.log('mode', mode);
      return mode;
    },
    setTestMode() {
      let mode = this.getModeFromUrl();
      if (mode) {
        Object.keys(testModes).forEach((key) => {
          if (testModes[key] === mode)
            this.$store.commit('setTestMode', mode);
        });
      } else {
        this.$store.commit('setTestMode', testModes.BASIC);
      }
    },
    checkBrowserCompatibility() {
      let browser = getBrowser()
      switch (getDeviceType()) {
        case deviceTypes.DESKTOP:
        case deviceTypes.MOBILE:
        case deviceTypes.TABLET:
          if (browser !== browserTypes.CHROME &&
              browser !== browserTypes.SAFARI &&
              browser !== browserTypes.FIREFOX &&
              browser !== browserTypes.EDGE) {
            this.incompatibleBrowser = true;
            this.showModalBrowserIncompatibility();
          } else {
            this.incompatibleBrowser = false;
          }
          break;
      }
    },
    showModalBrowserIncompatibility() {
      this.$store.commit('showModal',
          {
            componentToShowInsideModal: IncompatibleBrowser,
            argumentsToPassToAboveComponent: {},
            onModalButton1Click: () => {
              this.$store.commit('hideModal');
            },
          }
      );
    },
    verifyCanUserAccessTestWithActualDeviceType() {
      if (getDeviceType() === deviceTypes.MOBILE && this.$store.state.testMode === testModes.ADVANCED) {
        this.$store.commit('showModal',
            {
              componentToShowInsideModal: CantAccessAdvancedOnMobile,
              argumentsToPassToAboveComponent: {},
              onModalButton1Click: () => {
                let url = new URL(window.location.href);
                let mode = url.searchParams.get('mode');
                if (!mode || mode === testModes.ADVANCED) {
                  url.searchParams.set('mode', testModes.BASIC);
                  window.location.replace(url.toString());
                }
              },
              modalButtonActionText1: this.$t('global.go-to-basic-mode'),
            }
        );
      }
    },
    preventZooming() {
      document.addEventListener("keydown", function (e) {
        const zoomKeys = ["NumpadAdd", "Equal", "Minus", "NumpadSubtract", "Semicolon", "Slash"];
        if (e.ctrlKey && zoomKeys.includes(e.code)) {
          e.preventDefault();
        }
      });
      document.addEventListener(
          "wheel",
          function (e) {
            e.preventDefault();
          },
          {
            passive: false
          }
      );
    },
  },
  created() {
    this.setLanguage();
    this.updateScreenWarning();
    this.checkBrowserCompatibility();
    this.setTestMode();
    this.verifyCanUserAccessTestWithActualDeviceType();
    // this.preventZooming();
  },
};
</script>

export default {
  "page-results": {
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til hjemmet"])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Du vil sandsynligvis have gavn af at kontakte en optiker. Hvis optikeren ikke kan hjælpe dig med at se klart, er det vigtigt, at du finder ud af hvorfor. Det kan en øjenlæge hjælpe dig med.<br><br>Kontakt altid en optiker eller en øjenlæge, hvis du har medicinske spørgsmål eller bekymringer om dit syn."])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start en ny test"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis en optometrist ikke kan hjælpe dig med at se klart, er det vigtigt, at du finder ud af hvorfor.<br />Kontakt altid en øjenlæge for at finde ud af det."])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre"])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nu gennemført din læsesynstest\n"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["din vision er nedenfor"])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Resultater ved 40 cm:</b> venstre øje ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nu gennemført testen af dit afstandssyn\n"])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Resultater ved 2 m:</b> venstre øje ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højre øje"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venstre øje"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en ny test"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har identificeret ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols at a ", _interpolate(_named("distanceKey")), " distance.<br>Most people are able to identify ", _interpolate(_named("normalVisionSteps")), " out of the ", _interpolate(_named("steps")), " symboler korrekt."])},
    "normal-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalt syn"])},
    "snellen-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snellen-værdi"])},
    "acceptable-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptabelt syn"])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Tak, fordi du tog en DIY Digital Visual Acuity Test på eye-sight.org.<br>Kontakt altid en optiker eller en øjenlæge, hvis du har medicinske spørgsmål eller bekymringer om dit syn.<br>"])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Tak, fordi du tog Øjenforeningens gratis online gør det selv-synstest. Hvis du er usikker på, om du har et godt syn, kan du starte med at kontakte en optiker. Hvis optikeren ikke kan hjælpe dig med at se klart, er det vigtigt, at du finder ud af hvorfor. Det kan en øjenlæge hjælpe dig med.<br><p>Denne synstest:</p><ul><li>Er ikke medicinsk udstyr.Er</li><li>ikke egnet til medicinske formål.</li>Er<li>beregnet til raske personer.Er ikke beregnet til</li> at<li>erstatte en professionel syn</li>stest.<li>Indsamler ikke nogen personlige identificerbare data om dig eller nogen anden. </li></ol><p>Kontakt altid en optiker eller en øjenlæge, hvis du har medicinske spørgsmål eller bekymringer om dit syn.</p>"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forklaring"])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har et acceptabelt syn"])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har et normalt syn"])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har dårligt syn og vil have gavn af at tage en synstest hos en optometrist"])},
    "normal-basic-mode-vision-result-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatet af din test viser, at du har et <span class=\"bold\">normalt syn</span>."])},
    "acceptable-or-impaired-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt din øjenlæge for at drøfte dit resultat. Hvis en optiker ikke kan hjælpe dig med at se tydeligt, er det vigtigt, at du finder ud af hvorfor. Kontakt derfor altid en øjenlæge for at finde ud af, hvad der er skyld i, at du ikke ser tydeligt."])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Du har nu afsluttet din læsesynstest.</h3><p>Du har svaret, at du så ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symboler tydeligt på 40 cm afstand.<br>Andre kan se 36 ud af de 40 symboler tydeligt på samme afstand<sup>.*</sup><br> *<em class='small'> Kilde; Lisbeth Jelveh Sandfeld, ph.d., seniorkonsulent. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "poor-basic-mode-vision-result-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatet af din test viser, at du har et <span class=\"bold\">nedsat syn</span> og vil have gavn af at tage en synstest hos en optiker."])},
    "sub-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her er resultatet af den synstest, du lige har gennemført"])},
    "acceptable-advanced-mode-vision-result-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resultatet af din test viser, at din synsstyrke er ", _interpolate(_named("snellenResult")), ". Det betyder, at dit syn vurderes at være <span class=\"bold\">acceptabelt</span>, og at du vil have gavn af at tage en synstest hos en optiker."])},
    "normal-advanced-mode-vision-result-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resultatet af din test viser, at din synsstyrke er ", _interpolate(_named("snellenResult")), ". Det betyder, at dit syn\nvurderes at være <span class=\"bold\">normalt</span>."])},
    "thank-you-taking-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak fordi du har taget en gratis online gør det selv-synstest.<br />Husk at teste dit syn jævnligt og hjælp andre med at tage testen."])},
    "results-based-input-distance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resultatet er baseret på dine input i testen og forudsætter, at du har haft en afstand på ", _interpolate(_named("distance")), " mellem den skærm, du har taget testen på, og dine øjne."])},
    "poor-advanced-mode-vision-result-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resultatet af din test viser, at din synsstyrke er ", _interpolate(_named("snellenResult")), ". Det betyder, at dit syn\nvurderes at være <span class=\"bold\">nedsat</span>, og at du vil have gavn af at tage en synstest hos en\noptiker."])},
    "acceptable-basic-mode-vision-result-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatet af din test viser, at du har et <span class=\"bold\">acceptabelt syn</span> og vil have gavn af at tage en synstest hos en optiker."])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Du har nu gennemført din afstandssynstest.</h3><p>Du har svaret, at du så ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symboler tydeligt på 2 meters afstand.<br>Andre kan se 40 ud af de 40 symboler tydeligt på samme afstand<sup>.*</sup><br> *<em class='small'> Kilde; Lisbeth Jelveh Sandfeld, ph.d., seniorkonsulent. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit resultat"])},
    "poor-vision-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedsat syn"])},
    "for-everyone-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noter og gem resultaterne som led i den kliniske undersøgelse. Tak for din deltagelse i undersøgelsen. Tak fordi du har taget en gratis online gør det selv-synstest.<br /><br />Husk at teste dit syn jævnligt og hjælp andre med at tage testen."])}
  },
  "page-checklist": {
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har mine briller eller kontaktlinser på (kun hvis du normalt bruger dem)"])},
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har tilpasset skærmen med et standardkort (på størrelse med et  kreditkort)"])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har skruet op helt op for lysstyrken på min enhed"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK – jeg er klar"])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er indenfor"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft venligst"])},
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg holder skærmen vandret"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jeg har målt afstanden mellem mig selv og den skærm jeg tager testen på (", _interpolate(_named("distance")), ")"])}
  },
  "page-test": {
    "notice-too-many-errors": {
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Du har nu testet dit højre øje.<br>Du kan nu teste dit venstre øje.<br>Dæk dit <strong>højre</strong> øje til.<br>Hold skærmen ", _interpolate(_named("distance")), " væk fra dine øjne<br>Tryk på \"Næste runde\".</p>"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To fejl"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Du har nu testet dit venstre øje og gennemført testen. <br>Tryk på \"Vis resultater\".</p>"])}
    },
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trin"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nu testet dit højre øje."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Du kan nu teste dit venstre øje.<br>Dæk dit <strong>højre</strong> øje.<br>Hold skærmen ", _interpolate(_named("distance")), " væk fra dine øjne<br>Tryk på \"Næste runde\".</p>"])}
    },
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortryd"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venstre"])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis resultater"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ned"])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synstest"])},
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Testning</strong>"])},
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift øjne"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det er tid til at skifte øjne.<br />Du skal nu holde dig for højre øje imens du tester dit venstre øje.<br />Husk at holde en afstand på ", _interpolate(_named("distance")), " mellem dine øjne og skærmen."])},
    "as-you-test-your-right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["… imens du tester dit højre øje"])},
    "made-two-errors": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har lavet to fejl og det er tid til at skifte øjne.<br />Du skal nu holde dig for højre øje imens du tester dit venstre øje.<br />Husk at holde en afstand på ", _interpolate(_named("distance")), " mellem dine øjne og skærmen."])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold for dit venstre øje"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslut"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højre"])}
  },
  "page-scale": {
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Mindre</p>"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindre -5"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synstest"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Større +5"])},
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Større</p>"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindre -1"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Større +1"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Tilpas skærmen</h2><ol><li>Placer din enhed på et bord og placer et kort på størrelse med et kreditkort på skærmen.</li><li>Placer den smalle side af kortet mellem stregerne (skæremærkerne).</li><li>Brug knapperne (minus og plus) til at tilpasse skærmen.</li><li>Stregerne (skæremærkerne) på skærmen skal passe til den smalle side af dit kort.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Tilpas skærmen</h2><ol><li>Placer et kort på størrelse med et kreditkort på skærmen og placer den smalle side af dit kort mellem stregerne (skæremærkerne).</li><li>Brug knapperne (minus og plus) til at tilpasse skærmen.</li><li>Stregerne (skæremærkerne) på skærmen skal passe til den smalle side af dit kort.</li></ol>"])}
  },
  "page-in-full-brightness": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå inden for og skru så højt som muligt op for lysstyrken på din enhed."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuld lysstyrke"])}
  },
  "page-measure-40-cm": {
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer skærmen 40 cm fra dine øjne"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik her, hvis du ikke har en lineal eller en anden måde at måle afstanden på."])},
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placer din skærm 2 meter fra dine øjne"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et stykke A4-papir er ca. 20 cm bredt. <br>Placer to stykker A4-papir (lodret) ved siden af hinanden og du ved ca. hvor langt 40 cm er."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug en lineal<br>til at måle afstanden"])}
  },
  "page-how-it-works": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sådan virker det"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestem hvor i C-symbolerne der er en åbning.<br />Brug piletasterne, swipe-funktionen eller klik på knapperne for at angive dine svar."])}
  },
  "global": {
    "investigational-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun til undersøgelsesbrug"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 meter"])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkompatibel browser"])},
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essentielle ting"])},
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til grundlæggende tilstand"])},
    "screen-warning": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du bruger en smartphone og holder den lodret, så prøv at vippe telefonen vandret."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din skærm er for smal til at udføre DIY Digital Visual Acuity Test. "])}
    },
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg afstand"])},
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du tester dit højre øje (venstre øje tildækket)"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juster skærmen"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs instruktioner"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Øjenforeningen"]), _normalize(["Gratis online gør det selv-synstest"])])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke bruge den avancerede version på mobilen"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du tester dit venstre øje (højre øje tildækket)"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestem hvor åbningen er C-symbolerne. Brug piletasterne, swipe-funktionen eller en anden metode til at angive dine svar."])},
    "go-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besøg"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din aktuelle browser understøttes ikke. Brug venligst en af følgende browsere:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome til iOS eller Android"])},
    "going-to-about-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ved at forlade testen. Hvis du gør det, skal du starte forfra."])},
    "before-you-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Før du starter"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advarsel"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortryd"])},
    "go-to-about-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt til \"Om synstesten\""])},
    "about-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om synstesten"])},
    "about-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om testresultatet"])},
    "taking-the-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testen tages"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit resultat"])}
  },
  "page-intro": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synstest"])},
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Besøg hjemmesiden eye-sight.org for at få mere at vide</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen<br/>Ny Kongensgade 20<br/>1557 København"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til synstesten"])},
    "top-title-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til synstesten i den udvidede version"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne test kan vurdere din synsstyrke på to afstande: 40 cm. (læsesynet) og 2 meter (afstandssynet).<br />Du kan tage testen hjemme, på jobbet eller hvor det passer dig.<br />Testen understøtter opsporing af nedsat synsstyrke, og er beregnet til at vurdere, om du vil have glæde af en fuld synstest hos en optiker.<br /><br />Denne udgave af testen er kun beregnet til borgere i Danmark."])},
    "intro-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne test kan vurdere din synsstyrke på to afstande: 40 cm. (læsesynet) og 2 meter (afstandssynet). <br />Du kan tage testen hjemme, på jobbet eller hvor det passer dig.<br />Testen understøtter opsporing af nedsat synsstyrke, og er beregnet til at monitorere udviklingen af din synsstyrke og må kun bruges i forbindelse med kliniske undersøgelser forestået af øjenlæge Lisbeth Sandfeld.<br /><br />Denne udgave af testen er kun beregnet til borgere i Danmark."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlf. 33 69 11 00<br/>kontakt", "@", "ojenforeningen.dk"])},
    "investigational-only-advanced-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenlæge Lisbeth Sandfeld,<br />Øjenafdelingen, Sjællands Universitetshospital, 4000 Roskilde, Danmark"])}
  },
  "page-distance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synstest"])},
    "info": {
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Vejledning til test af læsesyn</h3><p>Du kan teste dit læsesyn alene.<br><span class='underline'>Sådan tester du dit læsesyn</span>:</p><div class='first'><div class='text'><p>1. Placer skærmen 40 cm fra dine øjne. Mål afstanden nøjagtigt.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in eleven rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Tryk på \"Fortsæt\", når du er klar.</p>"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instruktioner til test af afstandssyn</h3><p>Du skal bruge en hjælper til at teste dit afstandssyn.<br><span class='underline'>Sådan tester du dit afstandssyn</span>.</p><div class='first'><div class='text'><p>1. Placer din skærm 2 meter fra dine øjne. Mål afstanden nøjagtigt. Din hjælper skal stå ved siden af skærmen.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in twelve rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Your helper should enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Tryk på \"Test\", når I begge er klar.</p>"])}
    },
    "set": {
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Vælg afstand</h2>Du kan teste dit syn på to afstande:"])}
    },
    "select-distance": {
      "button-text-2-m-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 2 meter"])},
      "button-text-40-cm-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 40 cm"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg 2 meter"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg 40 cm"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm – læsesynet:<br />Du kan selv taget testen."])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgt"])},
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 meter – afstandssynet:<br />Du har brug for en hjælper til at tage testen."])}
    }
  },
  "page-keep-them": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold dem på"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug dine briller eller kontaktlinser når du tester. For eksempel læsebriller til 40 cm-testen og ’normale’ briller til 2 m-testen."])}
  },
  "page-keep-screen-horizontal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandret skærm"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold skærmen vandret mens du tager testen."])}
  },
  "page-proper-dimensions": {
    "proper-device-tablet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den faktiske skærmstørrelse på din enhed understøttes ikke<br />Du skal have en vinduesstørrelse på min. 8 cm x 3.3 cm<br />Skift venligst til en kompatibel enhed"])},
    "proper-device-desktop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den faktiske skærmstørrelse på din enhed understøttes ikke<br />Du skal have en vinduesstørrelse på min. 14 cm x 6.3 cm<br />Skift venligst til en kompatibel enhed"])}
  },
  "page-2-metres": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har brug for en hjælper til at tage 2 meter testen – afstandssynet"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold en afstand på 2 meter mellem dig selv og din skærm.\tDin hjælper skal angive dine svar."])}
  },
  "page-before-starting-test": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Før du starter"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal bruge et kort på størrelse med et kreditkort for at justere skærmindstillingerne, inden du kan starte testen."])}
  },
  "about-page": {
    "how-it-works-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennem testen er det din opgave at bestemme, hvor åbningen er placeret i en række C-ringe. Åbningen i C-ringen kan være placeret enten i toppen, i bunden, i venstre eller højre side. Alt afhængig af enheden, bruger du piletasterne, swipe-funktionen eller en anden metode til at angive dine svar. Synsstyrken måles i Snellen-brøker fra 6/6 (perfekt syn) til 6/60 (socialt blind). Jo mindre symboler du er i stand til at bestemme korrekt, jo bedre syn har du."])},
    "declaration-of-conformity-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens gratis, online gør det selv-synstest:<br>Erklæring om overensstemmelse"])},
    "what-it-means-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sådan skal det forstås"])},
    "basic-mode-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens gratis, online gør det selv-synstest har inddelt Snellen-brøkerne i tre kategorier: Normalt syn, acceptabelt syn og nedsat syn.<br /><br />Normalt syn (markeret med grønt) inkluderer resultater mellem 6/6 og 6/9.<br />Acceptabelt syn (markeret med gult) inkluderer resultater mellem 6/10 og 6/12.<br />Nedsat syn (markeret med rød) inkluderer resultater mellem 6/15 og 6/60."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om synstesten og dit testresultat"])},
    "how-it-works-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sådan virker det"])},
    "what-it-means-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatet af testen kan enten være normalt, acceptabelt eller nedsat syn.<br /><br />Hvis resultatet er normal syn, behøver du ikke at gøre mere.<br /><br />Hvis resultatet er acceptabelt eller nedsat syn, anbefaler vi, at du kontakter en optiker. Du vil have glæde af at tage en fuld synstest hos en professionel optiker. Kan en optiker ikke få dig til at se normalt, er det vigtigt, at du finder ud af hvorfor. Kontakt altid en øjenlæge for at finde ud af hvad der er skyld i, at du ikke kan se tydeligt."])},
    "what-it-means-text-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultatet af testen kan enten være normalt, acceptabelt eller nedsat syn. Det giver dig også resultatet som et tal (en brøk) svarende til Snellen-værdier, så du kan sammenligne, hvordan din synsstyrke udvikler sig over tid og dele oplysningerne med din læge. Til den kliniske undersøgelse er det brøken, du skal oplyse."])},
    "always-respect-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold altid den angivne afstand mellem den skærm som du tager testen på og dine øjne. Hvis afstanden er en anden – enten kortere eller længere – vil det påvirke resultatet og gøre det upræcist. Denne test kan tages på en afstand af henholdsvis 40 cm (læsesyn) eller 2 meter (afstandssyn).<br /><br />Du tester et øje ad gangen. Det er vigtigt, at du tildækker det andet øje, som anvist under testen."])},
    "advanced-mode-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens gratis, online gør det selv-synstest har inddelt Snellen-brøkerne i tre kategorier: Normalt syn, acceptabelt syn og nedsat syn.<br /><br />Normalt syn (markeret med grønt) inkluderer følgende resultater: 6/6, 6/7, 6/8, 6/9<br />Acceptabelt syn (markeret med gult) inkluderer følgende resultater: 6/10 og 6/12<br />Nedsat syn (markeret med rød) inkluderer følgende resultater: 6/15, 6/20, 6/30 og 6/60"])},
    "about-test-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens gratis, online gør det selv-synstest kan vurdere din synsstyrke på to afstande: 40 cm (læsesynet) og 2 meter (afstandssynet). Den er beregnet til at vurdere, om du vil have glæde af en fuld synstest hos en optiker. Denne udgave af testen er kun beregnet til borgere i Danmark.<br /><br />Synstesten er baseret på en ETDRS synstavle som du også møder hos din optiker eller øjenlæge. Testen er designet til at måle din synsstyrke – hvilket er din evne til at se tydeligt – ved hjælp af en række C-symboler i forskellige størrelser. Symbolernes størrelse og design er baseret på principperne bag ETDRS synstavlen."])},
    "about-test-text-advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens gratis, online gør det selv-synstest kan vurdere din synsstyrke på to afstande: 40 cm (læsesynet) og 2 meter (afstandssynet). Den er beregnet til at monitorere udviklingen af din synsstyrke og må kun bruges i forbindelse med kliniske undersøgelser forestået af øjenlæge Lisbeth Sandfeld.<br /><br />Denne udgave af testen er kun beregnet til borgere i Danmark.<br /><br />Synstesten er baseret på en ETDRS synstavle som du også møder hos din optiker eller øjenlæge. Testen er designet til at måle din synsstyrke – hvilket er din evne til at se tydeligt – ved hjælp af en række C-symboler i forskellige størrelser. Symbolernes størrelse og design er baseret på principperne bag ETDRS synstavlen."])},
    "declaration-of-conformity-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi erklærer, at Øjenforeningens gratis, online gør det selv-synstest og bærende CE-mærkning er i overensstemmelse med de generelle sikkerheds- og ydeevnekrav i forordningen om medicinsk udstyr MDR 2017/745 bilag I. Gældende harmoniserede standarder er blevet brugt til at demonstrere sikkerhed og effektivitet af enheden, når den bruges i overensstemmelse med dens tilsigtede brug og de medfølgende instruktioner.<br /><br />Følgende standarder blev anvendt:<br /><span class=\"body-bold\">Fremstilling</span>: EN ISO 13485:2016<br /><span class=\"body-bold\">Risikostyring</span>: EN ISO 14971:2019<br /><span class=\"body-bold\">Softwarelivscyklus</span>: EN 62304:2006/A1:2015<br/><br/>Hver version af softwaren frigives i overensstemmelse med optegnelser, der attesterer overholdelse af specifikationerne."])},
    "investigational-only-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun til undersøgelsesbrug"])},
    "investigational-only-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugen af udstyret er begrænset til den kliniske afprøvning, det må ikke bruges til andre formål."])}
  }
}
export default {
  "page-checklist": {
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosím brýle (pokud nosíte brýle)"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobře, jsem připraven"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední instrukce..."])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jsem uvnitř"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrazovku nechávám ve vodorovné poloze"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držím se u levého oka"])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úplně jsem zvýšil jas"])},
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změřil jsem, že mé oči jsou 40 cm / 2 metry od obrazovky."])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přizpůsobil jsem obrazovku na základě kreditní karty."])}
  },
  "page-test": {
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opusťte stránky"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další kolo"])},
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte své oko"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dole"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlevo"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátit poslední"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zakryjte si pravé oko a držte obrazovku ", _interpolate(_named("distance")), " dál od očí."])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahoru"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vpravo"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní jste otestovali své pravé oko."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Nyní si můžete otestovat levé oko.<br>Zakryjte si <strong>pravé</strong> oko.<br>Držte obrazovku ", _interpolate(_named("distance")), " dál od očí.<br>Stiskněte \"Next round\".</p>"])}
    },
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Testování</strong>"])},
    "notice-too-many-errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvě chyby"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Nyní jste otestovali své levé oko a dokončili test. <br>Stiskněte tlačítko \"Zobrazit výsledky\".</p>"])},
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Nyní jste otestovali pravé oko.<br>Nyní můžete otestovat levé oko.<br>Zakryjte si <strong>pravé</strong> oko.<br>Držte obrazovku ", _interpolate(_named("distance")), " dál od očí.<br>Stiskněte \"Next round\".</p>"])}
    },
    "made-two-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udělal jste dvě chyby."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začněte tím, že si zakryjete levé oko."])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit výsledky"])}
  },
  "global": {
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvolte vzdálenost"])},
    "screen-warning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše obrazovka je příliš úzká na to, abyste mohli provést digitální test zrakové ostrosti. "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud používáte chytrý telefon a držíte jej ve svislé poloze, zkuste jej naklonit do vodorovné polohy."])}
    },
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přechod do základního režimu"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základy"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přečtěte si instrukce"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš aktuální prohlížeč není podporován. Použijte prosím jeden z následujících prohlížečů:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome pro iOS nebo Android."])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilou verzi nelze používat v mobilním telefonu."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakryjte si pravé oko"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Test zraku"])])},
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakryjte si levé oko"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do otvoru kroužku vstupujte pomocí šipek, klikání na tlačítka nebo přejetí prstem."])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nekompatibilní prohlížeč"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 metry"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výsledky"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení obrazovky"])}
  },
  "page-scale": {
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Větší</p>"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Větší +1"])},
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Menší</p>"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Větší +5"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připraveno"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menší -1"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menší -5"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your mobile phone on a table.</li><li>Place a credit card sized card on the screen.</li><li>Place the narrow side of the card between the crop marks.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Nastavení velikosti obrazovky</h2><ol><li>Umístěte standardní kartu na obrazovku uvnitř ořezových značek.</li><li>Pomocí tlačítek upravte velikost obrazovky.</li><li>Upravte ořezové značky tak, aby odpovídaly šířce kreditní karty.</li></ol>"])}
  },
  "page-keep-screen-horizontal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Během testu musíte mít obrazovku ve vodorovné poloze."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udržujte obrazovku ve vodorovné poloze"])}
  },
  "page-results": {
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud vám optometrista nedokáže pomoci s jasným viděním, je důležité, abyste zjistili, proč tomu tak je.<br />Vždy se obraťte na oftalmologa, abyste to zjistili."])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr nového testu"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Nyní jste dokončili test vidění na dálku.</h3><p>Odpověděli jste, že jste na vzdálenost 2 metrů viděli zřetelně ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbolů.<br>Ostatní vidí na stejnou vzdálenost zřetelně 40 ze 40 symbolů<sup>.*</sup><br> *<em class='small'> Zdroj; Lisbeth Jelveh Sandfeld, PhD, senior konzultantka. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Výsledky ve vzdálenosti 2 m</b>: levé oko ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní jste dokončili zkoušku zraku na čtení\n"])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Výsledky ve vzdálenosti 40 cm</b>: levé oko ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vaše vize je níže"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatní"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vy"])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Děkujeme, že jste se zúčastnili DIY digitálního testu zrakové ostrosti na stránkách eye-sight.org. Pokud si nejste jisti, zda máte dobrý zrak, obraťte se nejprve na optika. Pokud vám oční lékař není schopen pomoci, abyste viděli jasně, je důležité, abyste zjistili proč. S tím vám může pomoci oční specialista (oftalmolog).<br><p>Tento test zraku:</p><ul><li>Není zdravotnickým prostředkem.</li><li>není vhodný pro žádné lékařské účely.</li><li>je určen pro zdravé jedince</li>.<li>nenahrazuje odborné vyšetření zraku</li>.<li>neshromažďuje žádné osobní údaje o vás ani o nikom jiném. </li></ol><p>Veškeré lékařské otázky nebo obavy týkající se vašeho zraku vždy konzultujte s optikem nebo očním specialistou.</p>"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Správně jste identifikovali symboly ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols at a ", _interpolate(_named("distanceKey")), " distance.<br>Most people are able to identify ", _interpolate(_named("normalVisionSteps")), " out of the ", _interpolate(_named("steps")), "."])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní jste dokončili test vidění na dálku\n"])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Pravděpodobně by vám pomohlo obrátit se na optika. Pokud vám oční lékař není schopen pomoci s jasným viděním, je důležité zjistit, proč tomu tak je. S tím vám může pomoci oční specialista (oftalmolog).<br><br>Veškeré zdravotní otázky nebo obavy týkající se vašeho zraku vždy konzultujte s optikem nebo očním specialistou."])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Děkujeme, že jste se zúčastnili DIY digitálního testu zrakové ostrosti na stránkách eye-sight.org.<br>Veškeré zdravotní otázky nebo obavy týkající se vašeho zraku vždy konzultujte s optikem nebo očním specialistou.<br>"])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Nyní jste dokončili zkoušku zraku pro čtení.</h3><p>Odpověděli jste, že jste na vzdálenost 40 cm viděli zřetelně ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbolů.<br>Ostatní vidí na stejnou vzdálenost zřetelně 36 ze 40 symbolů<sup>.*</sup><br> *<em class='small'> Zdroj; Lisbeth Jelveh Sandfeld, PhD, senior konzultantka. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])}
  },
  "page-distance": {
    "select-distance": {
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dlouhá vzdálenost s pomocníkem<br>asi 2 metry"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 2 metry"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čtecí vzdálenost podle sebe<br>asi 40 cm"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek 40 cm"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrané stránky"])}
    },
    "set": {
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Zvolte vzdálenostZrak</h2>si můžete otestovat ze dvou vzdáleností."])}
    },
    "info": {
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Pokyny ke zkoušce zraku na čtení</h3><p>Zrak na čtení si můžete otestovat sami.<br><span class='underline'>Jak si otestovat zrak na čtení:</span></p><div class='first'><div class='text'><p>1. Umístěte obrazovku 40 cm od očí. Přesně změřte vzdálenost.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in eleven rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Když jste připraveni, stiskněte tlačítko \"Pokračovat\".</p>"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Pokyny pro test vidění na dálku</h3><p>K testu vidění do dálky budete potřebovat pomocníka.<br><span class='underline'>Jak si otestovat vidění do dálky.</span></p><div class='first'><div class='text'><p>1. Umístěte obrazovku do vzdálenosti 2 metrů od vašich očí. Přesně změřte vzdálenost. Váš pomocník by měl stát u obrazovky.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in twelve rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Your helper should enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Až budete oba připraveni, stiskněte tlačítko \"Test\".</p>"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])}
  },
  "page-2-metres": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro test na dlouhou vzdálenost potřebujete pomocníka."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buďte 2 metry od počítače, váš pomocník by měl stát vedle obrazovky a bude vám ukazovat odpovědi."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])}
  },
  "page-how-it-works": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak to funguje"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte otvor kruhu pomocí <span class='bold'>směrové šipky na klávesnici</span> nebo <span class='bold'>klikněte na tlačítka</span> či <span class='bold'>přejeďte prstem</span>."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])}
  },
  "page-intro": {
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Navštivte webové stránky eye-sight.org a dozvíte se více.</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen Værn om Synet<br/>Ny Kongensgade 20<br/>1557 Copenhagen V"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens<br />bezplatný online test zraku pro vlastní potřebu"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test zraku"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Klasifikace:</span><br/>\nTřída I podle nařízení o zdravotnických prostředcích MDR 2017/745, příloha VIII, pravidlo 11.<br/><br/>\n\nProhlašujeme, že digitální testovací systém zrakové ostrosti DIY a opatřený označením CE splňuje obecné požadavky na bezpečnost a funkční způsobilost podle nařízení o zdravotnických prostředcích MDR 2017/745, příloha I. K prokázání bezpečnosti a účinnosti prostředku při jeho použití v souladu s určeným použitím a přiloženým návodem byly použity platné harmonizované normy.<br/><br/>\n\nByly použity následující normy:<br/><br/>\n\n<span class=\"body-bold\">Výrobní</span> normy: EN ISO 13485:2016<br/>\n<span class=\"body-bold\">Řízení rizik</span>: EN ISO 14971:2019<br/>\n<span class=\"body-bold\">Životní cyklus softwaru</span>: EN 62304:2006/A1:2015<br/><br/>\n\nKaždá verze softwaru je vydána v souladu se záznamy osvědčujícími shodu se specifikacemi"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontak", "@", "tojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])}
  },
  "page-keep-them": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud nosíte brýle nebo kontaktní čočky, ponechte si je."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponechte si je"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])}
  },
  "page-in-full-brightness": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V plném jasu"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvyšte podsvícení obrazovky až na doraz."])}
  },
  "page-before-starting-test": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezměte si standardní <span class=\"bold\">kartu</span>, bude pro vás při testu nezbytná."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Před zahájením testu"])},
    "take-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])}
  },
  "page-measure-40-cm": {
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístěte obrazovku 2 metry od očí"])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolik je 40 cm?"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změřte vzdálenost<br>pomocí pravítka"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 kusy papíru A4"])},
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístěte obrazovku 40 cm od očí"])}
  },
  "page-proper-dimensions": {
    "proper-device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skutečná velikost obrazovky vašeho zařízení není podporována<br />Měli byste mít okno o velikosti min. 24 cm x 15 cm<br />Přepněte prosím na kompatibilní zařízení."])}
  }
}
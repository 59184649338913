<template>
  <div class="left-or-right-eye-container text-center">
    <p class="body" v-html="this.$t('global.cant-use-advanced-with-mobile')"></p>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  methods: {},
};
</script>

class Logmar {
  constructor(value) {
    this.value = value;
  }

  get visual_acuity() {
    return Math.pow(10, -this.value);
  }

  diameter(testing_distance) {
    const angle = (5 * Math.PI) / (60 * 180);
    const l = this.visual_acuity;
    return testing_distance / (l * Math.sqrt(1 / Math.sin(angle) ** 2 - 1));
  }
}

export { Logmar };

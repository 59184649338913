import {createEntry, updateEntry} from "../../airtable";

let previousPath = '/';

export default {
    install(app) {
        app.config.globalProperties.$changePageWithAnalyticsStep = (store, to) => {
            if (store.state.firstLaunch) {
                store.commit('setFirstLaunch', false);
                store.commit('setCurrentPathCompleted', '0 - Welcome');
                createEntry(store);
            } else {
                switch (previousPath) {
                    case "PageBeforeStartingTest":
                        store.commit('setCurrentPathCompleted', '1 - Before you start');
                        updateEntry(store, '1-before-you-start');
                        break;
                    case "PageKeepThem":
                        store.commit('setCurrentPathCompleted', '2 - Keep them on');
                        updateEntry(store, '2-keep-them-on');
                        break;
                    case "PageInFullBrightness":
                        store.commit('setCurrentPathCompleted', '3 - Full brightness');
                        updateEntry(store, '3-full-brightness');
                        break;
                    case "PageKeepScreenHorizontal":
                        store.commit('setCurrentPathCompleted', '4 - Horizontal screen');
                        updateEntry(store, '4-horizontal-screen');
                        break;
                    case "PageScale":
                        store.commit('setCurrentPathCompleted', '5 - Adjust the screen');
                        updateEntry(store, '5-adjust-the-screen');
                        break;
                    case "PageDistance":
                        store.commit('setCurrentPathCompleted', '6 - Select distance');
                        updateEntry(store, '6-select-distance');
                        break;
                    case "Page2Metres":
                        store.commit('setCurrentPathCompleted', '7 - 2 metres helper');
                        updateEntry(store, '7-2metres-helper');
                        break;
                    case "PageMeasure40cm":
                        store.commit('setCurrentPathCompleted', '7 - 40cm helper');
                        updateEntry(store, '7-40cm-helper');
                        break;
                    case "PageChecklist":
                        store.commit('setCurrentPathCompleted', '8 - Checklist');
                        updateEntry(store, '8-checklist');
                        break;
                    case "PageHowItWorks":
                        store.commit('setCurrentPathCompleted', '9 - How it works');
                        updateEntry(store, '9-how-it-works');
                        break;
                }
            }
            previousPath = to;
            store.commit('changePage', to);
        }
        app.config.globalProperties.$analyticsEyes = (store, eye) => {
            if (eye === "right") {
                store.commit('setCurrentPathCompleted', '10 - Eye right test');
                updateEntry(store, '10-eye-right-test');
            } else {
                store.commit('setCurrentPathCompleted', '11 - Eye left test (to result)');
                updateEntry(store, '11-eye-left-test-to-result');
            }
        }
    },
};

<template>
  <div id="modal-root" v-if="showModal">
    <div id="modal-container">
      <div id="modal">
        <component :is="componentToShowInsideModal" v-bind="arguments"/>
        <div class="button-actions">
          <button class="btn primary-bg body-bold margin-top-s small-btn"
                  v-on:click="this.onModalButton1Click"
                  v-if="modalButtonActionText1">
            {{ modalButtonActionText1 }}
          </button>
          <button class="btn primary-bg body-bold margin-top-s small-btn"
                  v-on:click="this.onModalButton2Click"
                  v-if="modalButtonActionText2">
            {{ modalButtonActionText2 }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import LeftOrRightEye from "@/components/LeftOrRightEye";
import TooManyErrors from "@/components/TooManyErrors";
import Whats40cm from "@/components/Whats40cm";
import IncompatibleBrowser from "@/components/IncompatibleBrowser.vue";
import BeforeShowingAboutPage from "@/components/BeforeShowingAboutPage.vue";

let components = {
  LeftOrRightEye,
  TooManyErrors,
  Whats40cm,
  IncompatibleBrowser,
  BeforeShowingAboutPage,
};

export default {
  name: 'Modal',
  components: components,
  props: [],
  methods: {
    arrowKeyHandler(e) {
      if (e.code === 'Enter') {
        this.$store.state.onModalButton1Click();
      }
    },
  },
  computed: {
    showModal() {
      return this.$store.state.showModal;
    },
    componentToShowInsideModal() {
      return this.$store.state.componentToShowInsideModal;
    },
    modalButtonActionText1() {
      return this.$store.state.modalButtonActionText1;
    },
    modalButtonActionText2() {
      return this.$store.state.modalButtonActionText2;
    },
    arguments() {
      if (this.$store.state.argumentsToPassToAboveComponent != null) {
        return this.$store.state.argumentsToPassToAboveComponent;
      }

      return {};
    },
    onModalButton1Click() {
      return this.$store.state.onModalButton1Click();
    },
    onModalButton2Click() {
      return this.$store.state.onModalButton2Click();
    },
  },
  mounted() {
    window.addEventListener('keyup', this.arrowKeyHandler);
  },
  unmounted() {
    window.removeEventListener('keyup', this.arrowKeyHandler);
  },
}
</script>

import {createI18n} from 'vue-i18n'
//import config from '../vue.config.js';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

const locales = require.context('./locales', true, /[a-z]{2}\.json$/i);
const messages = {}
locales.keys().forEach(key => {
    const matched = key.match(/^\.\/([a-z]{2})\.json/i)
    if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key).default
    }
});

let fallbackLanguage = 'en';
let language;
let authorizedLanguages = ['en', 'da']

let browserLanguage = getUserLanguage();
browserLanguage = browserLanguage.split('-')[0];

language = authorizedLanguages.includes(browserLanguage) ? browserLanguage : fallbackLanguage;

let pathLangMatch = location.pathname.replace(process.env.VUE_APP_BASE_URL, '').match('^/?(\\w{2})(/.*?)?$');
pathLangMatch = pathLangMatch && pathLangMatch.length >= 2 && Object.keys(messages).includes(pathLangMatch[1]) ? pathLangMatch[1] : null;
language = pathLangMatch ? pathLangMatch : language;

function getUserLanguage() {
    return (
        // Modern browsers, including latest versions of Chrome, Firefox, Safari, etc.
        navigator.languages && navigator.languages[0] ||

        // For older versions of Chrome and Safari
        navigator.language ||

        // For older versions of Firefox
        navigator.browserLanguage ||

        // For older versions of IE
        navigator.userLanguage
    );
}

export default createI18n({
    legacy: false,
    locale: language,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || fallbackLanguage,
    messages: messages,
    globalInjection: true
})

export default {
  "page-distance": {
    "select-distance": {
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leseabstand selbst<br>ca. 40 cm"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 2 Meter"])},
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langstrecke mit einem Helfer<br>etwa 2 Meter"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 40 cm"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test des Augenlichts"])},
    "set": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Wählen Sie die EntfernungSie</h2>können Ihr Sehvermögen aus zwei Entfernungen testen"])},
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
    },
    "info": {
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Anleitung für den Lesesehtest</h3><p>Sie können Ihr Lese-Sehvermögen allein testen.<br><span class='underline'>So testen Sie Ihr Lese-Sehvermögen:</span></p><div class='first'><div class='text'><p>1. Stellen Sie den Bildschirm 40 cm von Ihren Augen entfernt auf. Messen Sie den Abstand genau.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"/>\"></div></div><div class='second'><div class='text'><p>2. Der Test zeigt in elf Durchgängen jeweils vier Kreise an. Zeigt die Öffnung der Kreise nach oben, nach unten, nach links oder nach rechts? Geben Sie Ihre Antworten ein, indem Sie über den Bildschirm streichen, die Pfeiltasten benutzen oder auf die Schaltflächen klicken.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("direction")), "\"/>\"></div></div><div class='third'><div class='text'><p>3. Testen Sie jeweils ein Auge auf einmal.</p></div></div><div class='fourth'><div class='text'><p>4. Beginnen Sie damit, Ihr <strong>linkes</strong> Auge abzudecken.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Drücken Sie auf \"Weiter\", wenn Sie bereit sind.</p>"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Anleitung für den Fernsehtest</h3><p>Für den Sehtest in der Ferne benötigen Sie eine Hilfsperson.<br><span class='underline'>So testen Sie Ihr Sehvermögen in der Ferne.</span></p><div class='first'><div class='text'><p>1. Stellen Sie den Bildschirm 2 Meter von Ihren Augen entfernt auf. Messen Sie die Entfernung genau. Ihre Hilfsperson sollte neben dem Bildschirm stehen.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"/>\"></div></div><div class='second'><div class='text'><p>2. Der Test zeigt jeweils vier Kreise in zwölf Runden. Zeigt die Öffnung der Kreise nach oben, nach unten, nach links oder nach rechts? Ihr Helfer sollte Ihre Antworten durch Wischen, mit den Pfeiltasten oder durch Klicken auf die Schaltflächen eingeben.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("direction")), "\"/>\"></div></div><div class='third'><div class='text'><p>3. Testen Sie jeweils ein Auge auf einmal.</p></div></div><div class='fourth'><div class='text'><p>4. Beginnen Sie damit, Ihr <strong>linkes</strong> Auge abzudecken.</p></div><div class='image'><img src=\"<variable name=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Drücken Sie auf \"Test\", wenn Sie beide fertig sind.</p>"])}
    }
  },
  "page-test": {
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nun Ihr rechtes Auge getestet."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Sie können nun Ihr linkes Auge testen.<br>Decken Sie Ihr <strong>rechtes</strong> Auge ab.<br>Halten Sie den Bildschirm ", _interpolate(_named("distance")), " von Ihren Augen entfernt<br>Drücken Sie \"Nächste Runde\".</p>"])}
    },
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie Ihr Auge"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktion rückgängig machen"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlassen Sie"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Runde"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daunen"])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach oben"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test des Augenlichts"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Decken Sie Ihr rechtes Auge ab und halten Sie den Bildschirm ", _interpolate(_named("distance")), " von Ihren Augen fern."])},
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Prüfung</strong>"])},
    "notice-too-many-errors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei Fehler"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Sie haben nun Ihr linkes Auge getestet und den Test abgeschlossen. <br>Drücken Sie auf \"Ergebnisse anzeigen\".</p>"])},
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Sie haben jetzt Ihr rechtes Auge getestet.<br>Jetzt können Sie Ihr linkes Auge testen.<br>Decken Sie Ihr <strong>rechtes</strong> Auge ab.<br>Halten Sie den Bildschirm ", _interpolate(_named("distance")), " von Ihren Augen entfernt<br>Drücken Sie \"Nächste Runde\".</p>"])}
    },
    "made-two-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben zwei Fehler gemacht."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnen Sie damit, Ihr linkes Auge abzudecken."])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse anzeigen"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt"])}
  },
  "page-in-full-brightness": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drehen Sie die Hintergrundbeleuchtung des Bildschirms ganz auf."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei voller Helligkeit"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "page-results": {
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten Sie einen neuen Test"])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Sie haben nun Ihren Sehtest für die Ferne abgeschlossen.</h3><p>Sie haben geantwortet, dass Sie ", _interpolate(_named("successful")), "\"/> der <variable name=\"", _interpolate(_named("steps")), " Symbole in einer Entfernung von 2 Metern deutlich sehen.<br>Andere können 40 der 40 Symbole in der gleichen Entfernung deutlich sehen<sup>.*</sup><br> *<em class='small'> Quelle; Lisbeth Jelveh Sandfeld, PhD, Senior Consultant. Opthalmologie, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück nach Hause"])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nun Ihren Lese-Sehtest abgeschlossen\n"])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vielen Dank, dass Sie den digitalen Sehschärfentest auf eye-sight.org gemacht haben.<br>Wenden Sie sich bei medizinischen Fragen oder Bedenken bezüglich Ihrer Sehkraft immer an einen Optiker oder einen Augenarzt.<br>"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Vision ist unten"])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Ergebnisse bei 40 cm:</b> linkes Auge ", _interpolate(_named("leftResult")), "\"/> und rechtes Auge <variable name=\"", _interpolate(_named("rightResult"))])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Ergebnisse bei 2 m:</b> linkes Auge ", _interpolate(_named("leftResult")), "\"/> und rechtes Auge <variable name=\"", _interpolate(_named("rightResult"))])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkes Auge"])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Sie haben nun Ihren Lesesehtest abgeschlossen.</h3><p>Sie haben geantwortet, dass Sie ", _interpolate(_named("successful")), "\"/> der <variable name=\"", _interpolate(_named("steps")), " Symbole in einer Entfernung von 40 cm deutlich gesehen haben.<br>Andere können 36 der 40 Symbole in derselben Entfernung deutlich sehen<sup>.*</sup><br> *<em class='small'> Quelle; Lisbeth Jelveh Sandfeld, PhD, Senior Consultant. Opthalmologie, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463. <a href='https://doi.org/10.1371/journal.pone.0188463'>https://doi.org/10.1371/journal.pone.0188463</a></em></p>"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen neuen Test"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben ", _interpolate(_named("successful")), "\"/> der <variable name=\"", _interpolate(_named("steps")), "\"/> Symbole in einem Abstand von <variable name=\"", _interpolate(_named("distanceKey")), "\"/> richtig erkannt.<br>Die meisten Menschen können <variable name=\"", _interpolate(_named("normalVisionSteps")), "\"/> aus den <variable name=\"", _interpolate(_named("steps")), " Symbolen erkennen."])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine schlechte Sehkraft und profitieren von einem Sehtest bei einem Optiker"])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben normales Sehvermögen"])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben ein akzeptables Sehvermögen"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erläuterung"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Augenarzt Ihnen nicht helfen kann, klar zu sehen, ist es wichtig, dass Sie herausfinden, warum.<br />Wenden Sie sich immer an einen Augenarzt, um das herauszufinden."])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtes Auge"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nun Ihren Sehtest für die Ferne abgeschlossen\n"])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Wahrscheinlich würde es Ihnen helfen, einen Optiker aufzusuchen. Wenn ein Optiker Ihnen nicht helfen kann, klar zu sehen, ist es wichtig, dass Sie herausfinden, warum. Ein Augenspezialist (Ophthalmologe) kann Ihnen dabei helfen.<br><br>Wenden Sie sich bei medizinischen Fragen oder Bedenken bezüglich Ihrer Sehkraft immer an einen Optiker oder Augenspezialisten."])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Vielen Dank, dass Sie einen digitalen Sehschärfentest auf eye-sight.org gemacht haben. Wenn Sie sich nicht sicher sind, ob Sie gut sehen können, sollten Sie sich zunächst an einen Optiker wenden. Wenn der Optiker Ihnen nicht helfen kann, klar zu sehen, ist es wichtig, dass Sie herausfinden, warum. Ein Facharzt für Augenheilkunde (Ophthalmologe) kann Ihnen dabei helfen.<br><p>Dieser Sehtest:</p><ul><li>ist kein medizinisches Gerät.</li><li>ist nicht für medizinische Zwecke geeignet.</li><li>ist für gesunde Personen gedacht.</li><li>ist nicht als Ersatz für einen professionellen Sehtest gedacht.</li><li>erhebt keine persönlichen Daten über Sie oder andere Personen. </li></ol><p>Wenden Sie sich bei medizinischen Fragen oder Bedenken bezüglich Ihrer Sehkraft immer an einen Optiker oder einen Augenarzt.</p>"])}
  },
  "page-before-starting-test": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nehmen Sie eine <span class=\"bold\">Standardkarte</span> mit, sie wird für die Prüfung unerlässlich sein."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor Beginn des Tests"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "take-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])}
  },
  "page-scale": {
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your mobile phone on a table.</li><li>Place a credit card sized card on the screen.</li><li>Place the narrow side of the card between the crop marks.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Bildschirmgröße anpassen</h2><ol><li>Legen Sie eine Standardkarte auf den Bildschirm innerhalb der Schnittmarken.</li><li>Passen Sie die Größe des Bildschirms mit den Schaltflächen an.</li><li>Passen Sie die Schnittmarken so an, dass sie mit der Breite Ihrer Kreditkarte übereinstimmen.</li></ol>"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test des Augenlichts"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größer +5"])},
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Größer</p>"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleiner -1"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größer +1"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleiner -5"])},
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Kleiner</p>"])}
  },
  "page-measure-40-cm": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was sind 40 cm?"])},
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie Ihren Bildschirm 2 Meter von Ihren Augen entfernt auf."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messen Sie den Abstand<br>mit einem Lineal"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Blatt A4-Papier"])},
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie den Bildschirm 40 cm von Ihren Augen entfernt auf."])}
  },
  "page-how-it-works": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie es funktioniert"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Ringöffnung mit dem <span class='bold'>Richtungspfeil der Tastatur</span> ein oder <span class='bold'>klicken Sie auf die Schaltflächen</span> oder <span class='bold'>wischen Sie</span>"])}
  },
  "page-checklist": {
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe gemessen, dass meine Augen 40 cm / 2 Meter vom Bildschirm entfernt sind"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich halte mich an das linke Auge"])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin drinnen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Anweisung..."])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Helligkeit komplett aufgedreht"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, ich bin bereit"])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe den Bildschirm auf der Grundlage einer Kreditkarte angepasst"])},
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich trage meine Brille (wenn Sie eine Brille tragen)"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich halte den Bildschirm horizontal"])}
  },
  "page-intro": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test des Augenlichts"])},
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Besuchen Sie die Website eye-sight.org, um mehr zu erfahren</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen Værn om Synet<br/>Ny Kongensgade 20<br/>1557 Kopenhagen V"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens<br />kostenloser Online-Sehtest zum Selbermachen"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Klassifizierung:</span><br/>\nKlasse I gemäß der Medizinprodukteverordnung MDR 2017/745, Anhang VIII, Regel 11.<br/><br/>\n\nWir erklären, dass das digitale DIY-Sehschärfentestsystem, das mit der CE-Kennzeichnung versehen ist, den allgemeinen Sicherheits- und Leistungsanforderungen der Medizinprodukteverordnung MDR 2017/745 Anhang I entspricht. Zum Nachweis der Sicherheit und Wirksamkeit des Geräts bei bestimmungsgemäßer Verwendung und gemäß der beiliegenden Anleitung wurden die geltenden harmonisierten Normen herangezogen.<br/><br/>\n\nDie folgenden Normen wurden verwendet:<br/><br/>\n\n<span class=\"body-bold\">Herstellung</span>: EN ISO 13485:2016<br/>\n<span class=\"body-bold\">Risikomanagement</span>: EN ISO 14971:2019<br/>\n<span class=\"body-bold\">Software-Lebenszyklus</span>: EN 62304:2006/A1:2015<br/><br/>\n\nJede Version der Software wird in Übereinstimmung mit Aufzeichnungen freigegeben, die die Einhaltung der Spezifikationen bescheinigen"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail kontak", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])}
  },
  "global": {
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Informationen"])},
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den Basismodus wechseln"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie die Ringöffnung mit der Pfeiltaste, Klickbuttons oder durch Wischen ein"])},
    "screen-warning": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie ein Smartphone verwenden und es vertikal halten, versuchen Sie, das Telefon horizontal zu neigen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Bildschirm ist zu schmal, um den digitalen Sehschärfetest durchführen zu können. "])}
    },
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedecken Sie Ihr linkes Auge"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleitung lesen"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Sehtest"])])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die erweiterte Version auf dem Handy nicht verwenden"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedecken Sie Ihr rechtes Auge"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr aktueller Browser wird nicht unterstützt. Bitte verwenden Sie einen der folgenden Browser:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome für iOS oder Android"])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkompatibler Browser"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Meter"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildschirm anpassen"])},
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernung wählen"])}
  },
  "page-keep-screen-horizontal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildschirm horizontal halten"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Ihren Bildschirm während des Tests waagerecht halten."])}
  },
  "page-proper-dimensions": {
    "proper-device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die tatsächliche Bildschirmgröße Ihres Geräts wird nicht unterstützt<br />Sie sollten eine Fenstergröße von mindestens 24cm x 15 cm haben<br />Bitte wechseln Sie zu einem kompatiblen Gerät"])}
  },
  "page-keep-them": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie eine Brille oder Kontaktlinsen tragen, behalten Sie sie."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalten Sie sie"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "page-2-metres": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie brauchen einen Helfer für den Langstreckentest"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halten Sie einen Abstand von 2 Metern zu Ihrem Computer, Ihr Helfer sollte neben dem Bildschirm stehen und Ihnen die Antworten anzeigen."])}
  }
}
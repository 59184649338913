export default {
  "page-test": {
    "quit-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlop"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test wzroku"])},
    "next-round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna runda"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W dół"])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lewa"])},
    "undo-last": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cofnij ostatnie"])},
    "change-eye-text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zakryj prawe oko i trzymaj ekran ", _interpolate(_named("distance")), " z dala od oczu."])},
    "up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W górę"])},
    "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawo"])},
    "notice-eye-shift": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetestowałeś teraz swoje prawe oko."])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Możesz teraz przetestować lewe oko.<br>Zakryj <strong>prawe</strong> oko.<br>Trzymaj ekran ", _interpolate(_named("distance")), " z dala od oczu<br>Naciśnij \"Następna runda\".</p>"])}
    },
    "notice-too-many-errors": {
      "next-eye": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Przetestowano prawe oko.<br>Teraz można przetestować lewe oko.<br>Zakryj <strong>prawe</strong> oko.<br>Trzymaj ekran ", _interpolate(_named("distance")), " z dala od oczu<br>Naciśnij \"Następna runda\".</p>"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dwa błędy"])},
      "test-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Test lewego oka został zakończony. <br>Naciśnij przycisk \"Pokaż wyniki\".</p>"])}
    },
    "now-testing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Testowanie</strong>"])},
    "made-two-errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popełniłeś dwa błędy."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "start-covering-left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zacznij od zakrycia lewego oka"])},
    "show-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż wyniki"])},
    "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
    "change-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień swoje oko"])}
  },
  "global": {
    "distance-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 cm"])},
    "select-distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz odległość"])},
    "screen-warning": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekran jest zbyt wąski, aby przeprowadzić cyfrowy test ostrości wzroku DIY. "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli korzystasz ze smartfona i trzymasz go pionowo, spróbuj przechylić telefon poziomo."])}
    },
    "go-to-basic-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do trybu podstawowego"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy"])},
    "read-instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeczytaj instrukcję"])},
    "cant-use-advanced-with-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można korzystać z wersji zaawansowanej na urządzeniach mobilnych"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "app-title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["eye-sight.org"]), _normalize(["Test wzroku"])])},
    "right-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakryj lewe oko"])},
    "enter-ring-opening-or-swiping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź otwór pierścienia za pomocą klawisza strzałki, przycisków kliknięcia lub przeciągnięcia palcem"])},
    "incompatible-browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niekompatybilna przeglądarka"])},
    "distance-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 metry"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyniki"])},
    "adjust-screen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulacja ekranu"])},
    "compatible-browsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja przeglądarka nie jest obsługiwana. Użyj jednej z następujących przeglądarek:<br /><br /><b>Desktop</b><br />Chrome, Safari, Firefox, Edge<br /><br /><b>Mobile</b><br />Safari, Firefox, Chrome dla iOS lub Android"])},
    "left-eye-cover-other-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakryj prawe oko"])}
  },
  "page-scale": {
    "larger-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Większy</p>"])},
    "larger-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Większy +1"])},
    "larger-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Większy +5"])},
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowy"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test wzroku"])},
    "smaller-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mniejsze -1"])},
    "intro-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Adjust the screen</h2><ol><li>Place your mobile phone on a table.</li><li>Place a credit card sized card on the screen.</li><li>Place the narrow side of the card between the crop marks.</li></ol>"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Dostosowywanie rozmiaru ekranu</h2><ol><li>Umieść standardową kartę na ekranie wewnątrz znaczników przycięcia.</li><li>Dostosuj rozmiar ekranu za pomocą przycisków.</li><li>Dostosuj znaczniki przycięcia tak, aby pokrywały się z szerokością karty kredytowej.</li></ol>"])},
    "smaller-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Mniejszy</p>"])},
    "smaller-more-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mniejszy -5"])}
  },
  "page-keep-screen-horizontal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas testu ekran musi być ustawiony poziomo."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuować"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utrzymywanie ekranu w poziomie"])}
  },
  "page-results": {
    "test-again-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz nowy test"])},
    "left-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "message-10-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Wyniki w odległości 2 m:</b> lewe oko ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "title-vision-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukończyłeś test wzroku podczas czytania\n"])},
    "poor-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "disclaimer-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "message-10-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b>Wyniki przy 40 cm:</b> lewe oko ", _interpolate(_named("leftResult")), " and right eye ", _interpolate(_named("rightResult"))])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoja wizja jest poniżej"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ty"])},
    "normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "acceptable-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "optometrist-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli optometrysta nie może pomóc w wyraźnym widzeniu, ważne jest, aby dowiedzieć się, dlaczego tak się dzieje.<br />Zawsze należy skontaktować się z okulistą, aby dowiedzieć się więcej."])},
    "right-eye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
    "go-back-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://eye-sight.org"])},
    "title-distance-test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test widzenia na odległość został zakończony\n"])},
    "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dziękujemy za wykonanie cyfrowego testu ostrości wzroku DIY na stronie eye-sight.org.<br>W przypadku jakichkolwiek pytań lub wątpliwości dotyczących wzroku należy zawsze skonsultować się z optykiem lub specjalistą w zakresie okulistyki.<br>"])},
    "disclaimer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Dziękujemy za wykonanie cyfrowego testu ostrości wzroku DIY na stronie eye-sight.org. Jeśli nie masz pewności, czy masz dobry wzrok, zacznij od skontaktowania się z optykiem. Jeśli optyk nie jest w stanie pomóc Ci widzieć wyraźnie, ważne jest, abyś dowiedział się dlaczego. Może w tym pomóc okulista.<br><p>Ten test wzroku:</p><ul><li>nie jest urządzeniem medycznym</li>,<li>nie nadaje się do celów medycznych</li>, jest<li>przeznaczony dla osób zdrowych</li>,<li>nie zastępuje profesjonalnego badania wzro</li>ku, nie gromadzi<li>żadnych danych osobowych użytkownika ani innych osób </li>.</ol><p>W przypadku jakichkolwiek pytań lub wątpliwości dotyczących wzroku należy zawsze skonsultować się z optykiem lub specjalistą w zakresie okulistyki.</p>"])},
    "message-5-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Ukończyłeś test widzenia na odległość.</h3><p>Odpowiedziałeś, że widziałeś wyraźnie symbole ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " z odległości 2 metrów.<br>Inni widzą wyraźnie 40 z 40 symboli z tej samej odległości<sup>.*</sup><br> *<em class='small'> Źródło; Lisbeth Jelveh Sandfeld, PhD, starszy konsultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463 <a href='https://doi.org/10.1371/journal.pone.0188463'>. https://doi.org/10.1371/journal.pone.0188463</a>.</em></p>"])},
    "message-5-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Ukończyłeś test widzenia do czytania.</h3><p>Odpowiedziałeś, że widziałeś wyraźnie symbole ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " z odległości 40 cm.<br>Inni widzą wyraźnie 36 z 40 symboli z tej samej odległości<sup>.*</sup><br> *<em class='small'> Źródło; Lisbeth Jelveh Sandfeld, PhD, starszy konsultant. Opthalmology, Baptista AMG, Serra PM, McAlinden C, Barrett BT (2017) Vision in high-level football officials. PLoS ONE 12(11): e0188463 <a href='https://doi.org/10.1371/journal.pone.0188463'>. https://doi.org/10.1371/journal.pone.0188463</a>.</em></p>"])},
    "first-paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prawidłowo zidentyfikowano symbole ", _interpolate(_named("successful")), " of the ", _interpolate(_named("steps")), " symbols at a ", _interpolate(_named("distanceKey")), " distance.<br>Most people are able to identify ", _interpolate(_named("normalVisionSteps")), " out of the ", _interpolate(_named("steps")), "."])},
    "second-paragraph-not-normal-vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<br>Prawdopodobnie korzystne byłoby skontaktowanie się z optykiem. Jeśli optyk nie jest w stanie pomóc w uzyskaniu wyraźnego widzenia, ważne jest, aby dowiedzieć się dlaczego. Może w tym pomóc specjalista chorób oczu (okulista).<br><br>Zawsze konsultuj się z optykiem lub specjalistą chorób oczu w przypadku jakichkolwiek pytań lub wątpliwości dotyczących Twojego wzroku."])}
  },
  "page-checklist": {
    "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, jestem gotowy"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia instrukcja..."])},
    "checkbox-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jestem w środku"])},
    "checkbox-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trzymam się lewego oka"])},
    "checkbox-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie zwiększyłem jasność"])},
    "checkbox-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmierzyłem, że moje oczy znajdują się 40 cm / 2 metry od ekranu"])},
    "checkbox-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostosowałem ekran na podstawie karty kredytowej"])},
    "checkbox-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noszę okulary (jeśli nosisz okulary)"])},
    "checkbox-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utrzymuję ekran w poziomie"])}
  },
  "page-distance": {
    "select-distance": {
      "title-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Długi dystans z pomocnikiem<br>około 2 metrów"])},
      "button-text-2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 2 metry"])},
      "button-text-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start 40 cm"])},
      "title-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samodzielna odległość odczytu<br>około 40 cm"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrane"])}
    },
    "set": {
      "back-to-start-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót"])},
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<h2 class='h-2'>Wybierz odległośćMożesz</h2>przetestować swój wzrok z dwóch odległości"])}
    },
    "info": {
      "start-test-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
      "back-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót"])},
      "message-2-m": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instrukcje dotyczące testu widzenia na odległość</h3><p>Do badania wzroku na odległość potrzebny będzie pomocnik.<br><span class='underline'>Jak zbad</span>ać wzrok na odległość.</p><div class='first'><div class='text'><p>1. Umieść ekran w odległości 2 metrów od oczu. Dokładnie zmierz odległość. Pomocnik powinien stać przy ekranie.</p></div><div class='image'><img src=\"", _interpolate(_named("distance2m")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in twelve rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Your helper should enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Naciśnij przycisk \"Test\", gdy oboje będziecie gotowi.</p>"])},
      "message-40-cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<h3>Instrukcje dotyczące testu wzroku podczas czytania</h3><p>Wzrok do czytania można sprawdzić samodzielnie.<br><span class='underline'>Jak sprawdzić wz</span>rok do czytania:</p><div class='first'><div class='text'><p>1. Umieść ekran w odległości 40 cm od oczu. Dokładnie zmierz odległość.</p></div><div class='image'><img src=\"", _interpolate(_named("distance40cm")), "\"></div></div><div class='second'><div class='text'><p>2. The test shows four circles at a time in eleven rounds. Is the opening in the circles pointing upwards, downwards, to the left or to the right? Enter your answers by swiping, using the arrow keys, or clicking on the buttons.</p></div><div class='image'><img src=\"", _interpolate(_named("direction")), "\"></div></div><div class='third'><div class='text'><p>3. Test one eye at a time.</p></div></div><div class='fourth'><div class='text'><p>4. Start by covering your <strong>left</strong> eye.</p></div><div class='image'><img src=\"", _interpolate(_named("coverLeft")), "\"></div></div><p class='center'>Naciśnij \"Kontynuuj\", gdy będziesz gotowy.</p>"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test wzroku"])}
  },
  "page-how-it-works": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jak to działa"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź otwór pierścienia za pomocą <span class='bold'>strzałki kierunkowej na klawiaturze</span> lub <span class='bold'>klikając przyciski</span> lub <span class='bold'>przesuwając palcem</span>."])}
  },
  "page-intro": {
    "learn-more-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href=\"\">Odwiedź stronę eye-sight.org, aby dowiedzieć się więcej</a>"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningen Værn om Synet<br/>Ny Kongensgade 20<br/>1557 Copenhagen V"])},
    "top-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Øjenforeningens<br />bezpłatny, internetowy test wzroku do samodzielnego wykonania"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test wzroku"])},
    "calibrate-screen-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"body-bold\">Klasyfikacja:</span><br/>\nKlasa I zgodnie z rozporządzeniem w sprawie wyrobów medycznych MDR 2017/745, załącznik VIII, zasada 11.<br/><br/>\n\nOświadczamy, że DIY Digital Visual Acuity Test System i opatrzony znakiem CE jest zgodny z ogólnymi wymogami dotyczącymi bezpieczeństwa i działania określonymi w rozporządzeniu w sprawie wyrobów medycznych MDR 2017/745, załącznik I. W celu wykazania bezpieczeństwa i skuteczności wyrobu używanego zgodnie z jego przeznaczeniem i dołączonymi instrukcjami zastosowano obowiązujące normy zharmonizowane.<br/><br/>\n\nZastosowano następujące normy:<br/><br/>\n\n<span class=\"body-bold\">Produkcja</span>: EN ISO 13485:2016<br/>\n<span class=\"body-bold\">Zarządzanie ryzykiem</span>: EN ISO 14971:2019<br/>\n<span class=\"body-bold\">Cykl życia oprogramowania</span>: EN 62304:2006/A1:2015<br/><br/>\n\nKażda wersja oprogramowania jest wydawana zgodnie z zapisami poświadczającymi zgodność ze specyfikacjami"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email kontak", "@", "ojenforeningen.dk<br/>Tel. +45 33 69 11 00<br/>ojenforeningen.dk"])}
  },
  "page-keep-them": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli nosisz okulary lub soczewki kontaktowe, zachowaj je."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zachowaj je"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])}
  },
  "page-in-full-brightness": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuować"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podświetl ekran do końca."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W pełnej jasności"])}
  },
  "page-before-starting-test": {
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuować"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przed rozpoczęciem testu"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weź standardową <span class=\"bold\">kartę</span>, będzie ona niezbędna podczas testu."])},
    "take-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])}
  },
  "page-2-metres": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bądź 2 metry od komputera, twój pomocnik powinien stać obok ekranu i wskazywać ci odpowiedzi."])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuować"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do testu długodystansowego potrzebny jest pomocnik"])}
  },
  "page-measure-40-cm": {
    "whats-40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile to jest 40 cm?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmierz odległość<br>za pomocą linijki"])},
    "2-pieces-a4-paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 kawałki papieru A4"])},
    "40-cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umieść ekran w odległości 40 cm od oczu"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuować"])},
    "2-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umieść ekran w odległości 2 metrów od oczu"])}
  },
  "page-proper-dimensions": {
    "proper-device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rzeczywisty rozmiar ekranu urządzenia nie jest obsługiwany<br />Rozmiar okna powinien wynosić co najmniej 24 cm x 15 cm<br />Przełącz się na zgodne urządzenie"])}
  }
}
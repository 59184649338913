<template>
  <div class="whats-40cm-container">
<!--    <h2 class="h-2 text-center">{{ $t('page-measure-40-cm.whats-40-cm-title') }}</h2>-->
    <div class="row margin-top-l margin-bottom-l">
      <ul>
        <li class="body" v-html="$t('page-measure-40-cm.2-pieces-a4-paper')"></li>
      </ul>

      <img src="@/assets/img/whats-40cm_da.svg" alt="Image" v-if="getLanguage() === 'da'" />
      <img src="@/assets/img/whats-40cm.svg" alt="Image" v-else />
    </div>
  </div>
</template>

<script>

import coverLeftEye from "@/assets/img/cover-left.svg";
import coverRightEye from "@/assets/img/cover-right.svg";
import {firstEye, secondEye} from "@/constants";

export default {

  components: {},
  props: [],
  data() {
    return {
      coverLeftEye,
      coverRightEye,
      firstEye,
      secondEye,
    };
  },
  methods: {
    getLanguage() {
      return this.$i18n.locale;
    },
  },
};
</script>

<template>
  <div class="left-or-right-eye-container" v-if="eyeToTest === firstEye">
    <h2 class="h-2 text-center">{{ $t('page-test.start-covering-left-eye') }}</h2>
    <p class="body text-center">
      {{
        $t('page-test.as-you-test-your-right-eye', {
          distance: this.getDistance()
        })
      }}
    </p>

    <img :src="coverLeftEye" alt="Image"/>
  </div>
  <div class="left-or-right-eye-container text-center" v-if="eyeToTest === secondEye">
    <h2 class="h-2 text-center">{{ $t('page-test.change-eye') }}</h2>
    <p class="body text-center" v-html="$t('page-test.change-eye-text', {
          distance: this.getDistance()
        })">
    </p>

    <img class="margin-right-l" :src="coverRightEye" alt="Image"/>
  </div>
</template>

<script>
import coverLeftEye from '@/assets/img/cover-left.svg';
import coverRightEye from '@/assets/img/cover-right.svg';
import {firstEye, secondEye} from "@/constants";
import {getIndexBasedOnDistance} from '@/utils/utils';
import i18n from "@/i18n";

export default {

  components: {},
  props: ['eyeToTest'],
  data() {
    return {
      coverLeftEye,
      coverRightEye,
      firstEye,
      secondEye,
      getIndexBasedOnDistance,
    };
  },
  methods: {
    getDistance() {
      return getIndexBasedOnDistance(this.$store.state.distance) === '40cm' ? i18n.global.t('global.distance-40-cm') : i18n.global.t('global.distance-2-m');
    }
  },
};
</script>

const creditCardDimensions = {
    width: 8.56, // cm
    height: 5.398, // cm
    ratio: 8.56 / 5.398, // width to height
};

const roundStatus = {
    SUCCESS: 1,
    FAILED: 2,
};

const noticeTypes = {
    EYESHIFT: "eye-shift",
    TOOMANYERRORS: "too-many-errors",
};

const directions = {
    LEFT: 2,
    RIGHT: 0,
    UP: 3,
    DOWN: 1,
};

const deviceTypes = {
    MOBILE: "mobile",
    DESKTOP: "desktop",
    TABLET: "tablet",
    UNKNOWN: "unknown",
}

const browserTypes = {
    FIREFOX: "firefox",
    CHROME: "chrome",
    SAFARI: "safari",
    EDGE: "edge",
    UNKNOWN: "unknown",
}

const testModes = {
    BASIC: "basic",
    ADVANCED: "advanced",
}

// first and second eye to be tested
const firstEye = "right";
const secondEye = "left";

// Max number of errors allowed per test
const maxErrorsPerTest = 2;

// Normal vision and poor vision in percent
const normalVisionPercent = 0.9;

const logmarBasicConversion = {
    "40cm": {
        index: 0,
        distance: 40, // cm,
        conversion: [
            {value: 1.0, foot: "20/200", meter: "6/60"},
            {value: 0.5, foot: "20/63", meter: "6/19"},
            {value: 0.3, foot: "20/40", meter: "6/12"},
            {value: 0.2, foot: "20/32", meter: "6/9"},
        ],
    },
    "2m": {
        index: 1,
        distance: 200, // cm
        conversion: [
            {value: 0.6, foot: "20/80", meter: "6/24"},
            {value: 0.5, foot: "20/63", meter: "6/19"},
            {value: 0.3, foot: "20/40", meter: "6/12"},
            {value: 0.2, foot: "20/32", meter: "6/9.5"},
            {value: 0.0, foot: "20/20", meter: "6/6"},
        ],
    },
};

const testBasicConfiguration = {
    "40cm": {
        index: 0,
        distance: 40, // cm
        logmar: [
            {value: 1.0, rings: 4},
            {value: 0.5, rings: 4},
            {value: 0.3, rings: 4},
            {value: 0.2, rings: 4},
        ],
    },
    "2m": {
        index: 1,
        distance: 200, // cm
        logmar: [
            {value: 0.6, rings: 4},
            {value: 0.5, rings: 4},
            {value: 0.3, rings: 4},
            {value: 0.2, rings: 4},
            {value: 0.0, rings: 4},
        ],
    },
};


const logmarAdvancedConversion = {
    "40cm": {
        index: 0,
        distance: 40, // cm,
        conversion: [
            {value: 1.2, foot: "20/320", meter: "6/95"},
            {value: 1.1, foot: "20/250", meter: "6/75"},
            {value: 1.0, foot: "20/200", meter: "6/60"},
            {value: 0.9, foot: "20/160", meter: "6/48"},
            {value: 0.8, foot: "20/125", meter: "6/38"},
            {value: 0.7, foot: "20/100", meter: "6/30"},
            {value: 0.6, foot: "20/80", meter: "6/24"},
            {value: 0.5, foot: "20/63", meter: "6/19"},
            {value: 0.4, foot: "20/50", meter: "6/15"},
            {value: 0.3, foot: "20/40", meter: "6/12"},
            {value: 0.2, foot: "20/32", meter: "6/9"},
        ],
    },
    "2m": {
        index: 1,
        distance: 200, // cm
        conversion: [
            {value: 1.1, foot: "20/250", meter: "6/75"},
            {value: 1.0, foot: "20/200", meter: "6/60"},
            {value: 0.9, foot: "20/160", meter: "6/48"},
            {value: 0.8, foot: "20/125", meter: "6/38"},
            {value: 0.7, foot: "20/100", meter: "6/30"},
            {value: 0.6, foot: "20/80", meter: "6/24"},
            {value: 0.5, foot: "20/63", meter: "6/19"},
            {value: 0.4, foot: "20/50", meter: "6/15"},
            {value: 0.3, foot: "20/40", meter: "6/12"},
            {value: 0.2, foot: "20/32", meter: "6/9"},
            {value: 0.1, foot: "20/25", meter: "6/7.5"},
            {value: 0.0, foot: "20/20", meter: "6/6"},
        ],
    },
};

const testAdvancedConfiguration = {
    "40cm": {
        index: 0,
        distance: 40, // cm
        logmar: [ // basic/pro test are same but pro shows more optotypes. Basic test will show only basic values with basic: true
            {value: 1.2, rings: 4},
            {value: 1.1, rings: 4},
            {value: 1.0, rings: 4},
            {value: 0.9, rings: 4},
            {value: 0.8, rings: 4},
            {value: 0.7, rings: 4},
            {value: 0.6, rings: 4},
            {value: 0.5, rings: 4},
            {value: 0.4, rings: 4},
            {value: 0.3, rings: 4},
            {value: 0.2, rings: 4},
        ],
    },
    "2m": {
        index: 1,
        distance: 200, // cm
        logmar: [ // basic/pro test are same but pro shows more optotypes. Basic test will show only basic values with basic: true
            {value: 1.1, rings: 2},
            {value: 1.0, rings: 3},
            {value: 0.9, rings: 4},
            {value: 0.8, rings: 4},
            {value: 0.7, rings: 4},
            {value: 0.6, rings: 4},
            {value: 0.5, rings: 4},
            {value: 0.4, rings: 4},
            {value: 0.3, rings: 4},
            {value: 0.2, rings: 4},
            {value: 0.1, rings: 4},
            {value: 0.0, rings: 4},
        ],
    },
};


export {
    creditCardDimensions,
    roundStatus,
    noticeTypes,
    directions,
    firstEye,
    secondEye,
    maxErrorsPerTest,
    testBasicConfiguration,
    logmarBasicConversion,
    logmarAdvancedConversion,
    testAdvancedConfiguration,
    normalVisionPercent,
    testModes,
    deviceTypes,
    browserTypes,
};

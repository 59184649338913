<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page">
      <div class="page-about">
        <h2 class="h-2 text-center" id="about">{{ $t('about-page.title') }}</h2>
        <div class="content margin-top-l margin-bottom-xl">
          <p v-html="isAdvancedMode ? $t('about-page.about-test-text-advanced') : $t('about-page.about-test-text')"></p>
          <h3 class="h-4 margin-top-s">{{ $t('about-page.how-it-works-title') }}</h3>
          <p v-html="$t('about-page.how-it-works-text')"></p>
          <div class="snellen-box">
            <div class="snellen-images">
              <img :src="snellenBarChartDa" alt="snellen bar + chart" v-if="getLanguage() === 'da'"/>
              <img :src="snellenBarChartEn" alt="snellen bar + chart" v-else/>
            </div>
            <p v-if="!isAdvancedMode" v-html="$t('about-page.basic-mode-text')"></p>
            <p v-if="isAdvancedMode" v-html="$t('about-page.advanced-mode-text')"></p>
          </div>
          <h3 class="h-4 margin-top-s">{{ $t('about-page.what-it-means-title') }}</h3>
          <p v-html="isAdvancedMode ? $t('about-page.what-it-means-text-advanced') : $t('about-page.what-it-means-text')"></p>
          <div class="respect-distance-content margin-top-m">
            <img :src="warningSign" alt="warning sign"/>
            <p class="margin-top-s" v-html="$t('about-page.always-respect-distance')"></p>
          </div>

          <div class="legal-information-content margin-top-xl">
            <div class="left">
              <img class="intro-img logo-img" :src="imgLogo" alt="ce-logo"/>
              <img class="intro-img factory-iso-img margin-top-s" :src="imgFactoryIso_7000_3082" alt="ce-logo"/>
              <div class="row factory-iso margin-top-m">
                <img class="intro-img img-factory-iso" :src="imgFactoryIso_7000_3082" alt="factory"/>
              </div>
              <p class="address margin-top-m" v-html="$t('page-intro.address')"></p>
              <p class="contact margin-top-m" v-if="!isAdvancedMode" v-html="$t('page-intro.contact')"></p>
            </div>
            <div class="right">
              <p class="body-bold margin-top-s margin-bottom-m">
                2024-07
              </p>
              <div class="logos">
                <img class="intro-img ref-md-udi-img img-md-logo" :src="imgMDLogo" alt="md-logo"/>
                <div class="row">
                  <img class="intro-img ref-md-udi-img img-ref-logo" :src="imgREFLogo" alt="ref-logo"/>
                  <p class="margin-left-s">Software version {{ versionMajorMinor }}</p>
                </div>
                <div class="row" v-if="!isAdvancedMode">
                  <img class="intro-img ref-md-udi-img img-udi-logo" :src="imgUDILogo" alt="udi-logo"/>
                  <p class="udi-placeholder margin-left-s">
                    +G5410010/$$+71.0E
                    <img class="intro-img img-udi-placeholder" :src="qrCodeBasic" alt="infos"/>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="investigational-only" v-if="isAdvancedMode">
            <div class="left">

            </div>
            <div class="right">
              <img class="intro-img investigational-only-img" :src="investigationalOnly" alt="infos"/>
              <p class="body-bold margin-top-s margin-bottom-m">
                {{ $t('global.investigational-only-title') }}
              </p>
              <p>
                {{ $t('about-page.investigational-only-text') }}
              </p>
            </div>
          </div>

          <h4 class="h-4 margin-top-xl" v-html="$t('about-page.declaration-of-conformity-title')" v-if="!isAdvancedMode"></h4>
          <p v-html="$t('about-page.declaration-of-conformity-text')" v-if="!isAdvancedMode"></p>

          <img class="intro-img img-ce-logo margin-top-l" :src="imgCELogo" alt="ce-logo" v-if="!isAdvancedMode" />

        </div>
      </div>

    </div>
  </component>
</template>

<script>
import imgInfos from '@/assets/pageIntro-infos-transparent-bg.png';
import imgFactoryIso_7000_3082 from '@/assets/iso_grs_7000_3082.svg';
import imgLogo from '@/assets/Logo_da.png';
import imgCELogo from '@/assets/ce_logo.svg';
import imgMDLogo from '@/assets/MD.svg';
import imgREFLogo from '@/assets/REF.svg';
import imgUDILogo from '@/assets/UDI.svg';
import qrCodeAdvanced from '@/assets/QR Code - ADVANCED.Url.png';
import qrCodeBasic from '@/assets/QR Code - BASIC.Url.png';
import packageJson from '@/../package.json';
import snellenBarChartEn from '@/assets/Snellen Bar_Chart.svg';
import snellenBarChartDa from '@/assets/Snellen Bar_Chart_da.svg';
import warningSign from '@/assets/Warning_Sign.svg';
import investigationalOnly from '@/assets/investigational_only.png';
import {testModes} from "@/constants";
import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox.vue";
import {warn} from "vue";

export default {
  methods: {
    warn,
    getLanguage() {
      return this.$i18n.locale;
    },
  },
  components: {PageWithColoredCenteredBox},
  data() {
    return {
      imgInfos,
      packageJson,
      imgFactoryIso_7000_3082,
      imgLogo,
      imgCELogo,
      imgMDLogo,
      imgREFLogo,
      imgUDILogo,
      qrCodeAdvanced,
      qrCodeBasic,
      snellenBarChartEn,
      snellenBarChartDa,
      warningSign,
      investigationalOnly,
    };
  },
  computed: {
    isAdvancedMode() {
      return this.$store.state.testMode === testModes.ADVANCED;
    },
    versionMajorMinor() {
      if (this.isAdvancedMode) {
        return this.packageJson.version_advanced.split('.').slice(0, 2).join('.');
      } else {
        return this.packageJson.version_basic.split('.').slice(0, 2).join('.');
      }
    },
  },
};
</script>

<template>
  <header class="navbar">
    <div class="container">
      <div class="left">
        <button class="btn small-btn" v-on:click="changePageOrGoToMain">
          {{ $t('page-test.quit-test') }}
        </button>
        <p class="body-bold app-navbar-title">{{ pageTitle }}</p>
      </div>

      <div class="right" v-if="this.$route.path !== '/about' && this.$store.state.currentPage !== 'PageAbout' && this.$store.state.showAboutButtonOnTopBar">
        <button class="btn small-btn margin-on-size-l-smaller" v-on:click="goToAboutPage">
          {{ $t('global.about-test') }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  components: {},
  props: ['pageTitle'],
  data() {
    return {};
  },
  methods: {
    changePageOrGoToMain() {
      try {
        this.exitFullScreen();
      } catch (e) {
        console.error(e);
      }
      if (this.$route.path === '/about') {
        this.$router.push('/');
      }
      this.$store.commit('changePage', 'PageIntro');
    },
    goToAboutPage() {
      try {
        this.exitFullScreen();
      } catch (e) {
        console.error(e);
      }
      if (this.$store.state.currentPage === 'PageResults') {
        this.$router.push('/about');
      } else {
        this.$store.commit('goToAboutPage')
      }
    },
    exitFullScreen() {
      if (document.fullscreenEnabled && document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  },
};
</script>

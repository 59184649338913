<template>
  <div class="left-or-right-eye-container text-center">
    <h2 class="h-2 text-center">{{ $t('global.incompatible-browser') }}</h2>
    <p class="body" v-html="this.$t('global.compatible-browsers')"></p>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  methods: {},
};
</script>
